import isEmpty from 'lodash.isempty';
import isNull from 'lodash.isnull';

import {initialState} from './initialState';
import {
    WISHLIST_ADD_FAILURE,
    WISHLIST_ADD_REQUEST,
    WISHLIST_ADD_SUCCESS,
    WISHLIST_LIST_FAILURE,
    WISHLIST_LIST_REQUEST,
    WISHLIST_LIST_SUCCESS,
    WISHLIST_MOVE_FAILURE,
    WISHLIST_MOVE_REQUEST,
    WISHLIST_MOVE_SUCCESS,
    WISHLIST_REMOVE_FAILURE,
    WISHLIST_REMOVE_REQUEST,
    WISHLIST_REMOVE_SUCCESS,
} from './wishlist.action';
import {productHelper} from '../../helpers/product.helper';

export function wishlist(state = initialState, action) {
    switch (action.type) {

        case WISHLIST_LIST_REQUEST: return {...state, isFetching: true};
        case WISHLIST_LIST_SUCCESS: return listSuccess(state, action);
        case WISHLIST_LIST_FAILURE: return {...state, isFetching: false};

        case WISHLIST_ADD_REQUEST: return {...state, isFetching: true};
        case WISHLIST_ADD_SUCCESS: return addToWishlistSuccess(state, action);
        case WISHLIST_ADD_FAILURE: return {...state, error: action.error, isFetching: false};

        case WISHLIST_REMOVE_REQUEST: return {...state, isFetching: true};
        case WISHLIST_REMOVE_SUCCESS: return removeFromWishlistSuccess(state, action);
        case WISHLIST_REMOVE_FAILURE: return {...state, error: action.error, isFetching: false};

        case WISHLIST_MOVE_REQUEST : return {...state, isFetching: true};
        case WISHLIST_MOVE_SUCCESS : return moveToSelection(state, action);
        case WISHLIST_MOVE_FAILURE : return {...state, error: action.error, isFetching: false};

        default:
            return state
    }
}

function listSuccess(state, action) {
    const suggestedWishlist = action.data
        .filter(wishlist => !isNull(wishlist.salesmanUser))
        .map(wishlist => ({...wishlist, assets: productHelper.getAvailableProducts(wishlist.assets)}))
    ;
    const userWishlist = action.data
        .filter(wishlist => isNull(wishlist.salesmanUser))
        .map(wishlist => ({...wishlist, assets: productHelper.getAvailableProducts(wishlist.assets)}))
        [0] || {};

    let selectedDesigns = suggestedWishlist.reduce((acc, suggested) => (acc = [...acc, ...suggested.wishlistProductDesignNumbers]), []);
    selectedDesigns = !isEmpty(userWishlist) ? [...selectedDesigns, ...userWishlist.wishlistProductDesignNumbers] : selectedDesigns;
    // const selectedDesigns = !isEmpty(user) ? user.wishlistProductDesignNumbers : [];

    return {
        ...state,
        selectedDesigns,
        user: userWishlist,
        suggested: suggestedWishlist,
        isFetching: false,
        loaded: true
    }
}

function addToWishlistSuccess(state, action) {
    const {id, designs, products} = action.data;

    const userDesigns = isEmpty(state.user) ? designs : [...state.user.wishlistProductDesignNumbers, ...designs];
    const assets = isEmpty(state.user) ? products : [...state.user.assets, ...products]
    const user = {
        id,
        salesmanUser: null,
        wishlistProductDesignNumbers: userDesigns,
        assets
    };

    const selectedDesigns = [...state.selectedDesigns, ...designs];

    return {
        ...state,
        user,
        selectedDesigns,
        isFetching: false
    }
}

function removeFromWishlistSuccess(state, action) {
    const {designs, id} = action.data;

    let user = JSON.parse(JSON.stringify(state.user));
    let suggestedList = JSON.parse(JSON.stringify(state.suggested));


    if (state.user.id === id) {
        const wishlistProductDesignNumbers = user.wishlistProductDesignNumbers.filter(designNumber => designs.indexOf(designNumber) === -1);
        const assets = user.assets.filter(asset => designs.indexOf(asset.productDesignNumber) === -1);

        user = {
            ...user,
            wishlistProductDesignNumbers,
            assets
        };
    } else {
        suggestedList = suggestedList.reduce((accumulator, suggested) => {
            if (suggested.id === id) {

                const wishlistProductDesignNumbers = suggested.wishlistProductDesignNumbers.filter(designNumber => designs.indexOf(designNumber) === -1);
                const assets = suggested.assets.filter(asset => designs.indexOf(asset.productDesignNumber) === -1);

                if (assets.length) {
                    accumulator.push({
                        ...suggested,
                        wishlistProductDesignNumbers,
                        assets
                    })
                }
            } else {
                accumulator.push(suggested)
            }

            return accumulator
        }, []);
    }

    const selectedDesigns = state.selectedDesigns.filter(designNumber => !designs.includes(designNumber));

    return {
        ...state,
        user,
        suggested: suggestedList,
        selectedDesigns,
        isFetching: false
    }
}

function moveToSelection(state, action) {

    const wishlistIds = action.data.reduce((accumulator, wishlist) => (accumulator = [...accumulator, wishlist.id]),[]);

    // filter suggested
    let suggested = [...state.suggested];

    if (!isEmpty(suggested)) {
        suggested = suggested.map(suggestedWishlist => {
            if (wishlistIds.indexOf(suggestedWishlist) !== -1 ) {
                const matchWishlist = action.data.filter(wishlist => wishlist.id === state.user.id)[0];
                const designs = matchWishlist.productDesignNumbers;

                suggestedWishlist.wishlistProductDesignNumbers = user.wishlistProductDesignNumbers.length > 0 ? user.wishlistProductDesignNumbers.filter(designNumber => designs.includes(designNumber)) : [];
                suggestedWishlist.assets = user.assets.filter(asset => designs.includes(asset.productDesignNumber));
            }

            return suggestedWishlist;
        })
    }

    // filter user
    let user = {...state.user};
    if (!isEmpty(user) && wishlistIds.indexOf(user.id) !== -1) {
        const matchWishlist = action.data.filter(wishlist => wishlist.id === state.user.id)[0];
        const designs = matchWishlist.productDesignNumbers;
        user.wishlistProductDesignNumbers = user.wishlistProductDesignNumbers.length > 0 ? user.wishlistProductDesignNumbers.filter(designNumber => designs.includes(designNumber)) : [];
        user.assets = user.assets.filter(asset => designs.includes(asset.productDesignNumber))
    }

    const designsToKeep = action.data.reduce((accumulator, wishlist) => {
        accumulator = [...accumulator, ...wishlist.productDesignNumbers]
        return accumulator;
    },[])
    const selectedDesigns = designsToKeep.length ? state.selectedDesigns.filter(designNumber => designsToKeep.includes(designNumber)) : [];

    return {
        ...state,
        user,
        suggested,
        selectedDesigns,
        isFetching: false
    }
}