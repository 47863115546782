import {orderService} from '../../services/order.service';

export const ORDER_REQUEST  = 'ORDER_REQUEST';
export const ORDER_SUCCESS  = 'ORDER_SUCCESS';
export const ORDER_FAILURE  = 'ORDER_FAILURE';

export const ORDER_SHARE_REQUEST  = 'ORDER_SHARE_REQUEST';
export const ORDER_SHARE_SUCCESS  = 'ORDER_SHARE_SUCCESS';
export const ORDER_SHARE_FAILURE  = 'ORDER_SHARE_FAILURE';

export const orderActions = {
    getOrders,
    shareOrder
};

function getOrders() {
    return async dispatch => {
        dispatch(request());

        const result = await orderService.getOrders();
        if (!result.error) {
            dispatch(success(result.data));
        }else{
            const error = { message: result.message };
            dispatch(failure(error));
        }
    };

    function request(data) { return { type: ORDER_REQUEST, data } }
    function success(data) { return { type: ORDER_SUCCESS, data } }
    function failure(error) { return { type: ORDER_FAILURE, error } }
}

function shareOrder(selection, lang, email) {
    return async dispatch => {
        dispatch(request());

        const result = await orderService.shareOrder(selection, lang, email);
        if (!result.error) {
            dispatch(success(selection));
        }else{
            const error = { message: result.message };
            dispatch(failure(error));
        }
    };

    function request(data) { return { type: ORDER_SHARE_REQUEST, data } }
    function success(data) { return { type: ORDER_SHARE_SUCCESS, data } }
    function failure(error) { return { type: ORDER_SHARE_FAILURE, error } }
}
