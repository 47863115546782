import {UserRole} from './types';

const authentication = JSON.parse(localStorage.getItem('authentication')) || {};
const role = authentication?.role || UserRole.UNAUTHORIZED;
const account =  authentication?.account || {};
const accessToken = localStorage.getItem('access_token') || '';

export const initialState = {
    role,
    accessToken,
    account,
    isFetching: false,
    error: ''
}