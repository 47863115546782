import {request} from '../helpers/request';
import qs from 'qs';

export const registrationService = {
    register
};

//POST registration form
async function register(form) {
    return await request.handleRequest('POST', `/account/register`, form);
}
