import React from 'react';
import {useDispatch} from 'react-redux';
import { Route, Redirect } from 'react-router-dom';
import {authenticationActions} from '../../store/authentication/authentication.action';
import {
    authentication,
} from '../../helpers/authentication.helper';

const PrivateRoute = ({component: Component, props, ...rest}) => {
    const dispatch = useDispatch();

    if (!authentication.checkAuthenticationToken()) {
        dispatch(authenticationActions.logout());
    }

    return (
        <Route {...rest} render={props => <Component {...props} />} />
    )
}
export default PrivateRoute;
