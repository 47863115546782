import {request} from '../helpers/request';

export const orderService = {
    getOrders,
    shareOrder
};

//GET user orders
async function getOrders() {
    return await request.handleRequest('GET', `/my-selections`);
}
//SHARE user selection
async function shareOrder(lang, preferenceId, email) {
    const formData = {
        'emails': [email]
    };
    return await request.handleRequest('POST', `/preferences/${preferenceId}/${lang}/share-pdf`, formData);
}
