import {brandService} from '../../services/brand.service';

export const BRANDS_LOADED  = 'BRANDS_LOADED';

export const BRANDS_DATA_REQUEST  = 'BRANDS_DATA_REQUEST';
export const BRANDS_DATA_SUCCESS  = 'BRANDS_DATA_SUCCESS';
export const BRANDS_DATA_FAILURE  = 'BRANDS_DATA_FAILURE';

export const BRANDS_DATA_CLEAR  = 'BRANDS_DATA_CLEAR';


export const brandActions = {
    brandsLoaded,
    getData,
    clearBrand
};


// EDIT PERSONAL DATA
function getData(form) {
    return async dispatch => {
        dispatch(request());

        const result = await brandService.getData();
        if (!result.error) {
            dispatch(success(result.data));
        } else {
            const error = { message: result.message };
            dispatch(failure(error));
        }
    };

    function request(data) { return { type: BRANDS_DATA_REQUEST, data } }
    function success(data) { return { type: BRANDS_DATA_SUCCESS, data } }
    function failure(error) { return { type: BRANDS_DATA_FAILURE, error } }
}

function brandsLoaded (data) {
    return { type: BRANDS_LOADED, data}
}

function clearBrand () {
    return { type: BRANDS_DATA_CLEAR}
}

