import React, {useEffect, useRef, useState} from 'react';
import { useTranslation } from 'react-i18next';
import Form from '../../../styles/Form';
import { useForm } from 'react-hook-form';

import crown from '../../../assets/reda/crown-white.svg';
import close from '../../../assets/icons/close-btn.png';

//style
import {
    StyledSelection,
    Title,
    Menu,
    Button,
    Submit,
    SelectionDetailWrapper,
    Message
} from './style';

const PopUp = (props) => {
    const node = useRef();
    const [open, setOpen] = useState(false);
    const [collection, setCollection] = useState('');
    const {brands, formSubmit} = props;
    const { t } = useTranslation();
    const { register, errors, watch, handleSubmit } = useForm();

    useEffect(() => {
        document.addEventListener("mousedown", handleClick);
        return () => {
            document.removeEventListener("mousedown", handleClick);
        };
    }, []);

    const handleClick = (e) => {
        if (node.current.contains(e.target)) {
            // inside click
            return;
        }
        // outside click
        setOpen(false);
        setCollection('');
    };

    const toggle = (e) => {
        e.preventDefault();
        setOpen(!open);
        setCollection('');
    };

    const submit = (data) => {
        setOpen(false);
        const formData = {...data, page: collection};
        formSubmit(formData);
        setCollection('');
    };

    const selectCollection = (e, collection) => {
        e.preventDefault();
        setCollection(collection);
    };

    return (
        <StyledSelection ref={node} className={open ? 'open' : ''}>
            {open && <SelectionDetailWrapper>
                <Title>
                    <div className="img-wrapper">
                        <img src={crown} alt="" height="20"/>
                    </div>

                    <a href="#" className="close" onClick={e => toggle(e)}>
                        <img src={close} alt="" height="20"/>
                    </a>
                </Title>
                <div className="content">
                    <Message>
                        <div className="box">
                            {t('PopUp.title')}
                        </div>
                    </Message>
                    <Menu>
                        { brands.map(brand =>
                            <li key={'brand-' + brand.name} style={{width: `${(100 / brands.length) - 2}%`}}>
                                <a href="#" className={collection === brand.name ? 'active' : ''} onClick={e => selectCollection(e, brand.name)}>
                                    {brand.name}
                                </a>
                            </li>
                        )}
                    </Menu>
                    {collection !== '' &&
                        <Form onSubmit={handleSubmit(submit)}>
                            <div className="textarea-wrapper">
                                <textarea name="request"
                                          ref={register({
                                              required: 'Text is required'
                                          })}
                                ></textarea>
                                {errors.request && <span className="form-error">{errors.request.message}</span>}
                            </div>
                            <Submit>
                                {t('PopUp.submit')}
                            </Submit>
                        </Form>
                    }
                </div>
            </SelectionDetailWrapper>}

            {!open &&
                <Button href="#" onClick={e => toggle(e)}>
                    <span>
                        {t('PopUp.primaryText')}
                    </span>
                    <svg version="1.1" className="livello-1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 76.1 60.9" style={{height: "19.2px"}}>
                        <g>
                            <path className="path" d="M19.9,52L6.1,59.1l4.6-12.2c-18-15.5-7.1-45,16.6-45c14.1,0,25.6,11.3,25.6,25.4C52.9,44.7,36.1,56.9,19.9,52z
                                     M60.8,46.5L71.2,52l-3.5-9.2c15.9-13.6,1.4-39.6-18.5-32.7c0.5,0.7,0.9,1.2,1.4,1.8c18-4.6,29.5,18.7,14.3,30l1.8,5.1l-5.8-3
                                    c-3.9,1.4-7.8,1.4-11.3,0.2c-0.5,0.7-0.9,1.2-1.6,1.8C52,47.7,56.4,47.9,60.8,46.5z M11.9,52.5l7.6-3.9
                                    c14.8,5.1,30.2-5.5,30.2-21.2C49.7,15.2,39.8,5,27.3,5C5.9,5-3.4,32.4,14.4,45.8L11.9,52.5z M36.9,35.1H18.8c-2.1,0-2.1-3.2,0-3.2
                                    h18.1C39,31.9,39,35.1,36.9,35.1z M41.4,25.6H15.3c-2.1,0-2.1-3.2,0-3.2h26.1C43.5,22.4,43.5,25.6,41.4,25.6z"/>
                        </g>
                    </svg>
                </Button>
            }

        </StyledSelection>
    )
};

export default PopUp;
