import axios from 'axios';

const getDomain = () => {
    return process.env.REACT_APP_API;
};

const handleRequest = async (type, url, data = {}, contentType = 'application/json') => {
    const headers = {'Content-Type': contentType};
    let response;
    try {
        response = await axios[type.toLowerCase()](`${getDomain()}${url}`, data, {headers});
    } catch (error) {
        let message = 'something went wrong';
        if (error.data?.error_description) {
            message = error.data.error_description;
        } else if (error.data?.ModelState && Object.keys(error.data.ModelState).length > 0) {
            message = error.data.ModelState[Object.keys(error.data.ModelState)[0]];
        } else if (error.data?.Message) {
            message = error.data.Message;
        }
        response = {
            error: true,
            message
        };
    }

    return response;
};

export const request = {
    handleRequest,
    getDomain
};
