import { pressAreaService } from '../../services/press-area.service';

export const PRESS_AREA_ARTICLES_REQUEST   = 'PRESS_AREA_ARTICLES_REQUEST';
export const PRESS_AREA_ARTICLES_SUCCESS   = 'PRESS_AREA_ARTICLES_SUCCESS';
export const PRESS_AREA_ARTICLES_FAILURE   = 'PRESS_AREA_ARTICLES_FAILURE';

export const pressAreaActions = {
    getArticles,
};


function getArticles() {
    return async dispatch => {
        dispatch(request());

        const result = await pressAreaService.getArticles();
        if (!result.error) {
            dispatch(success(result.data));
        }else{
            const error = { message: result.message };
            dispatch(failure(error));
        }
    };

    function request(data) { return { type: PRESS_AREA_ARTICLES_REQUEST, data } }
    function success(data) { return { type: PRESS_AREA_ARTICLES_SUCCESS, data } }
    function failure(error) { return { type: PRESS_AREA_ARTICLES_FAILURE, error } }
}
