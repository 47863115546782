import {
    PRESS_AREA_ARTICLES_REQUEST,
    PRESS_AREA_ARTICLES_SUCCESS,
    PRESS_AREA_ARTICLES_FAILURE
} from './press-area.action'

const initialState = {
    articles: [],
    isFetching: false
};

export function pressArea(state = initialState, action) {
    switch (action.type) {
        case PRESS_AREA_ARTICLES_REQUEST:
            return Object.assign({}, state, {
                articles: [],
                isFetching: true
            });
        case PRESS_AREA_ARTICLES_SUCCESS:
            return Object.assign({}, state, {
                articles: action.data,
                isFetching: false
            });
        case PRESS_AREA_ARTICLES_FAILURE:
            return Object.assign({}, state, {
                articles: [],
                isFetching: false
            });
        default:
            return state
    }
}
