import createToast from '../../factories/createToast';

export const ADD_TOAST = 'ADD_TOAST';
export const REMOVE_TOAST = 'REMOVE_TOAST';

export function addToast(options = {}) {
    return async dispatch => {
        const toast = createToast(options);
        dispatch(_addToast(toast));
        setTimeout(() => {
            dispatch(_removeToast(toast.id));
            if (options.callback) {
                dispatch(options.callback)
            }

        }, options.timeout || 5000);
    }
}

export function removeToast(id) {
    return async dispatch => {
        dispatch(_removeToast(id));
    }
}

function _addToast(toast) {
    return {
        payload: toast,
        type: ADD_TOAST
    }
}

function _removeToast(id) {
    return {
        payload: id,
        type: REMOVE_TOAST
    };
}
