import {designService} from '../../services/design.service';

export const DESIGN_DATA_REQUEST  = 'DESIGN_DATA_REQUEST';
export const DESIGN_DATA_SUCCESS  = 'DESIGN_DATA_SUCCESS';
export const DESIGN_DATA_FAILURE  = 'DESIGN_DATA_FAILURE';

export const designActions = {
    getData
}

function getData(sku, season) {
    return async dispatch => {
        dispatch(request());

        const result = await designService.getDesignData(sku, season);
        if (!result.error) {
            dispatch(success(
                {
                    sku,
                    products: result.data
                }
            ));
        }else{
            const error = { message: result.message };
            dispatch(failure(error));
        }
    };

    function request() { return { type: DESIGN_DATA_REQUEST } }
    function success(data) { return { type: DESIGN_DATA_SUCCESS, data } }
    function failure(error) { return { type: DESIGN_DATA_FAILURE, error } }
}
