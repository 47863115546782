import {userService} from '../../services/user.service'
import {analyticsHelper} from '../../helpers/analytics.helper';

export const ACCOUNT_REQUEST    = 'ACCOUNT_REQUEST';
export const ACCOUNT_SUCCESS    = 'ACCOUNT_SUCCESS';
export const ACCOUNT_FAILURE    = 'ACCOUNT_FAILURE';

export const EDIT_PERSONAL_DATA_REQUEST   = 'EDIT_PERSONAL_DATA_REQUEST';
export const EDIT_PERSONAL_DATA_SUCCESS   = 'EDIT_PERSONAL_DATA_SUCCESS';
export const EDIT_PERSONAL_DATA_FAILURE   = 'EDIT_PERSONAL_DATA_FAILURE';

export const CHANGE_EMAIL_REQUEST   = 'CHANGE_EMAIL_REQUEST';
export const CHANGE_EMAIL_SUCCESS   = 'CHANGE_EMAIL_SUCCESS';
export const CHANGE_EMAIL_FAILURE   = 'CHANGE_EMAIL_FAILURE';

export const CHANGE_PASSWORD_REQUEST   = 'CHANGE_PASSWORD_REQUEST';
export const CHANGE_PASSWORD_SUCCESS   = 'CHANGE_PASSWORD_SUCCESS';
export const CHANGE_PASSWORD_FAILURE   = 'CHANGE_PASSWORD_FAILURE';

export const NEWSLETTER_REQUEST   = 'NEWSLETTER_REQUEST';
export const NEWSLETTER_SUCCESS   = 'NEWSLETTER_SUCCESS';
export const NEWSLETTER_FAILURE   = 'NEWSLETTER_FAILURE';

export const VIDEO_FINISHED = 'VIDEO_FINISHED';
export const VIDEO_REWATCH = 'VIDEO_REWATCH';

export const userActions = {
    // getAccountData,
    editPersonalData,
    changeEmail,
    changePassword,
    newsLetterRegistration,
    videoFinished,
    rewatchVideo
};

// function getAccountData() {
//     return async dispatch => {
//         dispatch(request());
//
//         const result = await userService.getAccountData();
//
//         if (!result.error) {
//             console.log('RESULT', result);
//             dispatch(success(result.data));
//
//             if(result.data.isSalesman) {
//                 setCookie('salesManId', result.data.id, 365);
//             }
//         }else{
//             const error = { message: result.message };
//             dispatch(failure(error));
//         }
//     };
//
//     function request(data) { return { type: ACCOUNT_REQUEST, data } }
//     function success(data) { return { type: ACCOUNT_SUCCESS, data } }
//     function failure(error) { return { type: ACCOUNT_FAILURE, error } }
// }

// EDIT PERSONAL DATA
function editPersonalData(form) {
    return async dispatch => {
        dispatch(request({form}));

        const result = await userService.edit(form);
        if (!result.error) {
            dispatch(success(form));
            return true;
        } else {
            const error = { message: result.message };
            dispatch(failure(error));
            return false;
        }
    };

    function request(data) { return { type: EDIT_PERSONAL_DATA_REQUEST, data } }
    function success(data) { return { type: EDIT_PERSONAL_DATA_SUCCESS, data } }
    function failure(error) { return { type: EDIT_PERSONAL_DATA_FAILURE, error } }
}

// CHANGE EMAIL
function changeEmail(form) {
    return async dispatch => {
        dispatch(request({form}));

        const result = await userService.changeEmail(form);
        if (!result.error) {
            dispatch(success(form));
            return true;
        } else {
            const error = { message: result.message };
            dispatch(failure(error));
            return false;
        }
    };

    function request(data) { return { type: CHANGE_EMAIL_REQUEST, data } }
    function success(data) { return { type: CHANGE_EMAIL_SUCCESS, data } }
    function failure(error) { return { type: CHANGE_EMAIL_FAILURE, error } }
}

// CHANGE PASSWORD
function changePassword(form) {
    return async dispatch => {
        dispatch(request({form}));

        const result = await userService.changePassword(form);
        if (!result.error) {
            dispatch(success(form));
            return true;
        } else {
            const error = { message: result.message };
            dispatch(failure(error));
            return false;
        }
    };

    function request(data) { return { type: CHANGE_PASSWORD_REQUEST, data } }
    function success(data) { return { type: CHANGE_PASSWORD_SUCCESS, data } }
    function failure(error) { return { type: CHANGE_PASSWORD_FAILURE, error } }
}

function newsLetterRegistration(form) {
    return async dispatch => {
        dispatch(request({form}));

        const result = await userService.newsLetterRegistration(form);
        if (!result.error) {
            dispatch(success(form));

            analyticsHelper.nlSubscriptionsGATrack();

            return true;
        } else {
            const error = { message: result.message };
            dispatch(failure(error));
            return false;
        }
    };

    function request(data) { return { type: NEWSLETTER_REQUEST, data } }
    function success(data) { return { type: NEWSLETTER_SUCCESS, data } }
    function failure(error) { return { type: NEWSLETTER_FAILURE, error } }
}

function videoFinished(video) {
    return { type: VIDEO_FINISHED, video }
}

function rewatchVideo(video) {
    return { type: VIDEO_REWATCH, video }
}