import isEmpty from 'lodash.isempty';
import isNull from 'lodash.isnull';
import urlSlug from 'url-slug';

const filterValidAttributes = [
    'exclusive',
    'sku',
    'article',
    'productArticleNumber',
    'productDesignNumber',
    'capsuleCollections',
    'page',
    'collection',
    'categories',
    'type',
    'composition',
    'realComposition',
    'color',
    'weight',
    'fineness',
    'design',
    'performances',
    'worlds',
    'weightFilter'
];

const getValidFilterAttribute = (attribute) => {
    const lowerFilterValidAttributes = filterValidAttributes.map(filterAttribute => filterAttribute.toLowerCase())
    const index = lowerFilterValidAttributes.indexOf(attribute.toLowerCase());
    return filterValidAttributes[index];
}

function filterByAttributes (products, attributesToCheck, filtersValue) {
    return products.filter(product => {
        const attributesFound = filtersValue.map(value => {
            return attributesToCheck.filter(attribute => {
                if (Array.isArray(product[attribute])) {
                    return product[attribute].some(attrValue => attrValue.toLowerCase() === value.toLowerCase())
                } else if (typeof product[attribute] === 'string') {
                    return product[attribute].toLowerCase() === value.toLowerCase();
                } else {
                    // Exclusive is a boolean
                    return product[attribute] === value;
                }
            }).length >= 1
        });
        return attributesFound.filter(found => found).length === filtersValue.length;
    });
}

// get all the available filters for the given product list
const getFilterList = (headerFilters, products) => {
    if (!headerFilters) return {}
    // normalize the filters value
    const normalizedFilters = filterValidAttributes.reduce((acc,filter) => {
        acc = headerFilters.includes(filter.toLowerCase()) ? [...acc, filter] : acc;
        return acc;
    },[]);
    return normalizedFilters.reduce((accumulator, attribute) => {
        // create an array of unduplicated filter options
        const attributeValues = [...new Set(
            products
            .filter(product => (!isNull(product[attribute]) && !isEmpty(product[attribute])))
            .reduce((acc, product) => {
                const value = product[attribute];

                acc = Array.isArray(value) ? [...acc, ...value] : [...acc, value];
                return acc;
            }, [])
        )];

        if (!isEmpty(attributeValues)) { accumulator[attribute.toLowerCase()] = attributeValues; }
        return  accumulator
    }, {})
};

// combine and return the filter list
const combineFilterAttribute = (currentFilters, selectedFilterType, selectedFilterValue) => {
    let updatedActiveFilters = {...currentFilters};

    // normalize the type to match with product
    selectedFilterType = getValidFilterAttribute(selectedFilterType);

    updatedActiveFilters[selectedFilterType] = !updatedActiveFilters[selectedFilterType] ? [] : updatedActiveFilters[selectedFilterType];
    const index = !isNull(selectedFilterValue) ? updatedActiveFilters[selectedFilterType].indexOf(selectedFilterValue) : null;

    //check if filter is to add or remove
    if(index === -1 && !isNull(index)) {
        updatedActiveFilters[selectedFilterType].push(selectedFilterValue);
    }else{
        if (isNull(index)) {
            delete updatedActiveFilters[selectedFilterType];
        } else {
            updatedActiveFilters[selectedFilterType] = updatedActiveFilters[selectedFilterType].filter((filter, fIndex) => fIndex !== index );
            if(!updatedActiveFilters[selectedFilterType].length) {
                delete updatedActiveFilters[selectedFilterType];
            }
        }
    }
    return !isEmpty(updatedActiveFilters) ? updatedActiveFilters : null;
}

// toggle and return the filter list
const toggleFilterAttribute = (currentFilters, selectedFilterType, selectedFilterValue) => {
    let updatedActiveFilters = {...currentFilters};

    if (
        updatedActiveFilters.hasOwnProperty(selectedFilterType) &&
        updatedActiveFilters[selectedFilterType].find(filter => filter === selectedFilterValue)
    ) {
        delete updatedActiveFilters[selectedFilterType];
    } else {
        updatedActiveFilters[selectedFilterType] = [selectedFilterValue]
    }

    return !isEmpty(updatedActiveFilters) ? updatedActiveFilters : null;
}

// filter a list of product by the given attributes
const filterProductByAttributes = (products, attributes) => {
    if (isNull(attributes)) { return products }

    const attributesToCheck = Object.keys(attributes);
    const filtersValue = Object.values(attributes).reduce((accumulator, value) => (
        accumulator = Array.isArray(value) ? [...accumulator, ...value] : [...accumulator, value]
    ), [])

    return filterByAttributes(products, attributesToCheck, filtersValue);
}

// filter the list of products according to the passed attributes and the searched values
const filterProductBySearchAttributes = (products, attributesToCheck, filtersValue) => {
    return filterByAttributes(products, attributesToCheck, filtersValue);
}

const removeActiveFilters = (filters, activeFilters) => {
    if (isNull(activeFilters) || isEmpty(activeFilters)) { return null }

    // filter active filters and remove only the filters in the filters list (left side)
    return Object.keys(activeFilters).reduce((accumulator, aFilter) => {
        if (filters.indexOf(aFilter) !== -1) { return accumulator; }

        accumulator[aFilter] = activeFilters[aFilter];
        return accumulator;
    },{})
}

// SELECTION / WISHLIST

// create the structure for the filters: {page: {collection: {category: #color-variant-number#}}
const getFilterByCollectionCategory = (products, activeFilters) => {
    //TODO scrivere le condizioni in una maniera più decente
    return products.reduce((acc, product) => {
        const page = urlSlug(product.page).toLowerCase();
        const collection = urlSlug(product.collection).toLowerCase();

        if (isEmpty(activeFilters)) {
            acc[page] = !acc[page] ? {} : acc[page];
            acc[page][collection] = !acc[page][collection] ? {} : acc[page][collection];

            // count the color variant foreach category
            product.categories.forEach(category => (
                acc[page][collection][category] = !acc[page][collection][category] ? 1 : acc[page][collection][category] +1
            ))
        } else {
            if(activeFilters[page] && activeFilters[page][collection]) {
                acc[page] = !acc[page] ? {} : acc[page];
                acc[page][collection] = !acc[page][collection] ? {} : acc[page][collection];

                // count the color variant foreach category
                product.categories.forEach(category => (
                    acc[page][collection][category] = !acc[page][collection][category] ? 1 : acc[page][collection][category] +1
                ))
            }
        }

        return acc;
    }, {})
}

const getCollectionCategoryActiveFilters = (page, collection, category, activeFilters) => {
    page = urlSlug(page).toLowerCase();
    collection = urlSlug(collection).toLowerCase();

    // when changing page the filters are reset
    let updateFilters =  Object.keys(activeFilters).indexOf(page) === -1 ? {} : {...activeFilters}
    updateFilters[page] = !updateFilters[page] ? {} : updateFilters[page];

    // a category already present is removed
    // a collection without categories is removed

    // create or update collection structure
    updateFilters[page][collection] = Object.keys(updateFilters[page]).indexOf(collection) === -1 ? []
        : updateFilters[page][collection];
    // check if category is already present in the given collection, if it is remove it
    updateFilters[page][collection] = updateFilters[page][collection].indexOf(category) === -1 ? [...updateFilters[page][collection],category]
        : updateFilters[page][collection].filter(collectionCategory => collectionCategory !== category);
    // clean structure
    if (!updateFilters[page][collection].length) { delete updateFilters[page][collection] }

    return !Object.keys(updateFilters[page]).length ? {} : updateFilters;
}

const filterProductByCollectionCategory = (products, page, activeFilters) => {
    page = urlSlug(page).toLowerCase();
    const pageProducts = products.filter(product => urlSlug(product.page).toLowerCase() === page);
    if (isEmpty(activeFilters)) return pageProducts

    return pageProducts.filter(pageAsset => {
        if (Object.keys(activeFilters).indexOf(pageAsset.page) === -1) { return false; }
        const collection = Object.keys(activeFilters[page]).find(collection => collection === urlSlug(pageAsset.collection).toLowerCase());

        return collection ? activeFilters[page][collection].every(category => pageAsset.categories.includes(category)) : false
    })
}

export const filterHelper = {
    // model
    filterValidAttributes,
    //normalize
    getValidFilterAttribute,
    // catalog
    getFilterList,
    combineFilterAttribute,
    toggleFilterAttribute,
    filterProductByAttributes,
    removeActiveFilters,
    // search
    filterProductBySearchAttributes,
    // selection / wishlist
    getFilterByCollectionCategory,
    getCollectionCategoryActiveFilters,
    filterProductByCollectionCategory
}
