import React from 'react';
import styled from 'styled-components';
import {NavLink} from "react-router-dom";
import { useTranslation } from 'react-i18next';

//assets
import logo from '../../assets/cerruti/logo-white.png';
import youtube from '../../assets/icons/youtube.svg';
import instagram from '../../assets/icons/instagram.svg';
import facebook from '../../assets/icons/facebook.svg';

const StyledFooter = styled.div`
  background-color: ${props => props.theme.footerBg};
  padding: 30px 15px 15px;
  min-height:360px;
  color: white;
  
  .header{
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 25px;

    .logo img{
        height: 75px;
        width: auto;
    }
  }
  .footer{
    margin-top: 25px;
    font-size: 12px;
    line-height: 1.4;
  }
  
  .menu{
    display: flex;
    min-height: 50%;
    flex-wrap: wrap;
    > * {
      flex-basis: 50%;
      margin-bottom: 25px;
    }
    margin-top: 38px;
    align-content: center;
    
    
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr 1fr 1fr;
    
    //TABLET
    @media only screen and (max-width: ${props => props.theme.mediaQuery.md}) {
      display: none;
    }
    
    a{
      position: relative;
      display: inline-block;
      text-transform: uppercase;
      color: #FFFFFF;
      text-align: left;
      
      .active, &:hover{
        &:after{
          content: '';
          position: absolute;
          left: 0;
          bottom: -1px;
          width: 100%;
          height: 1px;
          background-color: #FFFFFF;
        }
      }
    } 
  }
`;

const AccordionMenu = styled.ul`
  display: none;
  //TABLET
  @media only screen and (max-width: ${props => props.theme.mediaQuery.md}) {
    display: block;
    margin-bottom: 30px;
  }
  
  li{
    position: relative;
    padding-bottom: 15px;
    margin-bottom: 15px;
    
    a{
      display: block;
      font-size: 18px;
    }
    
    .content{
      display: none;
    }
     
    &.open{
      .content{
        display: block;
        > * {
          margin-top: 15px;
        }
        
        a{
          font-size: 14px;
        }
      }
    };
    
    &:before{
      content: '';
      position: absolute;
      left: 0;
      bottom: -1px;
      width: 100%;
      height: 1px;
      background-color: #FFFFFF;
    }
    
    &::after{
      content: '';
      position: absolute;
      top: 7px;
      right: 0;
      width: 0; 
      height: 0;
      border-left: 5px solid transparent;
      border-right: 5px solid transparent;
      border-top: 5px solid #FFFFFF;
    }
    
    
    &:last-of-type{
      padding-bottom: 0;
      margin-bottom: 0;
    
      &:before{
        display: none;
      }
    }
  }   
`;

const Social = styled.ul`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    
    //MOBILE
    @media only screen and (max-width: ${props => props.theme.mediaQuery.sm}) {
      display: block;
      width: auto;
      margin: 0 auto;
    }
    
    li{
      margin: 0 5px;
      @media only screen and (max-width: ${props => props.theme.mediaQuery.sm}) {
        display: inline-block;
      }
    }
`;

const FooterCerruti = props => {
    const { t } = useTranslation();

    const openMenu = (e) => {
        e.preventDefault();
        const parent = e.target.parentElement;
        if ( parent.className.replace(/[\n\t]/g, ' ').indexOf('open') > -1 ) {
            parent.classList.remove('open');
        } else {
            parent.classList.add('open');
        }
    };

    return (
        <StyledFooter className="container">
            {/* HEADER */}
            <div className="row header">
                <div className="col-xs-hide col-sm-6">
                    <NavLink to="/" className="logo">
                        <img src={logo} alt="" height="40" width="150"/>
                    </NavLink>
                </div>
                <div className="col-xs-12 col-sm-6 social">
                    <div className="row center-xs end-sm">
                        <div className="col-xs-12 col-sm-9">
                            <Social>
                                <li>
                                    <a target="_blank" href="https://www.youtube.com/channel/UCd8aOYs5gamk2lue44hREyQ" >
                                        <img  src={youtube} alt="" width="40"/>
                                    </a>
                                </li>
                                <li>
                                    <a target="_blank" href="https://www.instagram.com/lanificiocerruti/">
                                        <img  src={instagram} alt="" width="40"/>
                                    </a>
                                </li>
                                <li>
                                    <a target="_blank" href="https://it-it.facebook.com/LanificioCerruti/">
                                        <img  src={facebook} alt="" width="40"/>
                                    </a>
                                </li>
                            </Social>
                        </div>
                    </div>
                </div>
            </div>
            {/* CONTENT */}
            <div className="row between-xs">
                <div className="col-xs-12 col-sm-4 col-md-6">
                    <ul className="menu">
                        <li>
                            <NavLink to="/group" title={t('Footer.group')}>
                                {t('Footer.group')}
                            </NavLink>
                        </li>
                        <li>
                            <NavLink to="/customer-service" title={t('Footer.customerService')}>
                                {t('Footer.customerService')}
                            </NavLink>
                        </li>
                        <li>
                            <a href="https://www.lanificiocerruti.com/privacy-policy-1/" target="_blank" title={t('LegalArea.privacyPolicy.title')}>
                                {t('LegalArea.privacyPolicy.title')}
                            </a >
                        </li>
                        <li>
                            <a href="https://www.lanificiocerruti.com/cookie-policy/" target="_blank" title={t('LegalArea.cookiePolicy.title')}>
                                {t('LegalArea.cookiePolicy.title')}
                            </a >
                        </li>

                    </ul>

                    <AccordionMenu>
                        <li>
                            <a href="#" onClick={e => openMenu(e)}>CORPORATE INFO</a>
                            <div className="content">
                                <NavLink to="/group" title={t('Footer.group')}>
                                    {t('Footer.group')}
                                </NavLink>

                                <a href="https://www.lanificiocerruti.com/press-releases/" target="__blank" title="Area Stampa">
                                    {t('Footer.press')}
                                </a>
                            </div>
                        </li>
                        <li>
                            <a href="#" onClick={e => openMenu(e)}>{t('Footer.customerService')}</a>
                            <div className="content">
                                <NavLink to="/customer-service" title={t('Footer.customerService')}>
                                    {t('Footer.customerService')}
                                </NavLink>
                                <NavLink to="/contacts" title="Contattaci">
                                    {t('Footer.contacts')}
                                </NavLink>
                                <a href="https://www.lanificiocerruti.com/privacy-policy-1/" target="_blank" title={t('LegalArea.privacyPolicy.title')}>
                                    {t('LegalArea.privacyPolicy.title')}
                                </a >
                                <a href="https://www.lanificiocerruti.com/cookie-policy/" target="_blank" title={t('LegalArea.cookiePolicy.title')}>
                                    {t('LegalArea.cookiePolicy.title')}
                                </a >
                                <a href="https://www.privacylab.it/informativa.php?12291381090" target="_blank" title={t('LegalArea.copyrightAndDisclaimer.title')}>
                                    {t('LegalArea.copyrightAndDisclaimer.title')}
                                </a >
                            </div>
                        </li>
                    </AccordionMenu>
                </div>
                <div className="col-xs-12 col-sm-4 col-md-6">
                    <ul className="menu">
                        <li>
                            <a href="https://www.privacylab.it/informativa.php?12291381090" target="_blank" title={t('LegalArea.copyrightAndDisclaimer.title')}>
                                {t('LegalArea.copyrightAndDisclaimer.title')}
                            </a >
                        </li>
                        <li>
                            <a href="https://www.lanificiocerruti.com/press-releases/" target="__blank" title={t('Footer.press')}>
                                {t('Footer.press')}
                            </a>
                        </li>
                        <li>
                            <NavLink to="/contacts" title="Contattaci">
                                {t('Footer.contacts')}
                            </NavLink>
                        </li>
                    </ul>
                </div>
            </div>
            {/*  FOOTER */}
            <div className="row footer">
                <div className="col-xs-12">
                    {t('Footer.cerruti.address')}
                </div>
            </div>
        </StyledFooter>
    );
};

export default FooterCerruti;
