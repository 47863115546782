import {supportService} from '../../services/support.service';
import { addToast } from '../toasts/toasts.action';

export const SUPPORT_REQUEST  = 'SUPPORT_REQUEST';
export const SUPPORT_SUCCESS  = 'SUPPORT_SUCCESS';
export const SUPPORT_FAILURE  = 'SUPPORT_FAILURE';

export const supportActions = {
    supportRequest,
};

function supportRequest(formData) {
    return async dispatch => {
        dispatch(request());

        const result = await supportService.supportRequest(formData);
        if (!result.error) {
            dispatch(success(result.data));
            dispatch(addToast({text: 'SupportRequest.success'}))
        }else{
            const error = { message: result.message };
            dispatch(failure(error));
        }
    };

    function request(data) { return { type: SUPPORT_REQUEST, data } }
    function success(data) { return { type: SUPPORT_SUCCESS, data } }
    function failure(error) { return { type: SUPPORT_FAILURE, error } }
}
