import {request} from '../helpers/request';

export const contactService = {
    sendRequest
};

async function sendRequest(data) {
    const formData = {
        name: data.user,
        email: data.email,
        phone: data.phone,
        request: data.message
    };
    return await request.handleRequest('POST', `/contact-us`, {...formData});
}
