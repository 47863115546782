import {AllowedRoles} from '../store/authentication/types';

const isAuthenticated = (role, accessToken) => {
    return !!(AllowedRoles.indexOf(role) !== -1 && accessToken);
};

const checkAuthenticationToken = () => {
    return !!(localStorage.getItem('access_token'));
}

export const authentication = {
    checkAuthenticationToken,
    isAuthenticated
}
