import {SEASON_LOADED} from "./season.action";

const initialState = {
    result: {},
    loaded: false
};

export function seasons(state = initialState, action) {
    switch (action.type) {
        case SEASON_LOADED:
            return {
                ...state,
                result: {
                    old: action.data.data.old,
                    current: action.data.data.current,
                    pre: action.data.data.pre
                }
            }
        default:
            return state
    }
}
