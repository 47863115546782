import { contactService } from '../../services/contact.service';

export const CONTACT_REQUEST   = 'CONTACT_REQUEST';
export const CONTACT_SUCCESS   = 'CONTACT_SUCCESS';
export const CONTACT_FAILURE   = 'CONTACT_FAILURE';

export const contactActions = {
    sendRequest,
};


function sendRequest(data) {
    return async dispatch => {
        dispatch(request());

        const result = await contactService.sendRequest(data);
        if (!result.error) {
            dispatch(success(result.data));
        }else{
            const error = { message: result.message };
            dispatch(failure(error));
        }
    };

    function request(data) { return { type: CONTACT_REQUEST, data } }
    function success(data) { return { type: CONTACT_SUCCESS, data } }
    function failure(error) { return { type: CONTACT_FAILURE, error } }
}
