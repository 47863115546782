import React from 'react';
import {connect} from 'react-redux';
import {withTranslation} from 'react-i18next';

import './style.scss'

//components
import PopUp from './PopUp/PopUp';
//actions
import {supportActions} from '../../store/support/support.action';

class SupportButton extends React.Component {

    sendSupportRequest = (data) => {
        const form = {...data};
        this.props.supportRequest(form);
    };

    render() {
        const {brand} = this.props;
        return (
            <div className="support-button-component">
                <div className="support-button">
                    <PopUp brands={brand?.results || []} formSubmit={this.sendSupportRequest} />
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state, props) => ({
    brand: state.brand
});
const mapDispatchToProps = dispatch => ({
    supportRequest: (formData) => dispatch(supportActions.supportRequest(formData))
});
export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(SupportButton))

