export const catalogInitialState = {
    products: [],
    articles: {},
    designs: {},
    filters: {},
    settings: []
}

export const initialState = {
    loaded: false,
    viewMode: 'article',

    activeFilters: null,
    activeSearch: null,
    activeSeason: localStorage.getItem('REDA_CATALOG_ACTIVE_SEASON') || null,
    currentPage: 0,
    error: null,
    isFetching: false
}
