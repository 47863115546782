import React from 'react';
import styled from 'styled-components';
import {NavLink} from "react-router-dom";
import { useTranslation } from 'react-i18next';
import i18n from "../../i18n/i18n";

//PDF
import ethicCodeIT from '../../assets/reda/pdf/reda_code_of_ethics_it.pdf';
import ethicCodeEN from '../../assets/reda/pdf/reda_code_of_ethics_en.pdf';

//components
import NewsLetter from '../NewsLetter/NewsLetter';

//assets
import crown from '../../assets/reda/crown-white.svg';
import bCorp from '../../assets/reda/b-corp.svg';
import youtube from '../../assets/icons/youtube.svg';
import instagram from '../../assets/icons/instagram.svg';
import facebook from '../../assets/icons/facebook.svg';
import linkedin from '../../assets/icons/linkedin.svg';

const StyledFooter = styled.div`
  background-color: ${props => props.theme.footerBg};
  padding: 30px 15px 15px;
  color: white;
  
  .header{
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 25px;

  }
  .footer{
    margin-top: 25px;
    font-size: 12px;
    line-height: 1.4;
  }
  
  .menu{
    display: flex;
    min-height: 50%;
    flex-wrap: wrap;
    > * {
      flex-basis: 50%;
      margin-bottom: 25px;
    }
    margin-top: 38px;
    align-content: center;
    
    
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr 1fr 1fr;
    
    //TABLET
    @media only screen and (max-width: ${props => props.theme.mediaQuery.md}) {
      display: none;
    }
    
    a{
      position: relative;
      display: inline-block;
      text-transform: uppercase;
      color: #FFFFFF;
      text-align: left;
      
      .active, &:hover{
        &:after{
          content: '';
          position: absolute;
          left: 0;
          bottom: -1px;
          width: 100%;
          height: 1px;
          background-color: #FFFFFF;
        }
      }
    } 
  }
`;
const AccordionMenu = styled.ul`
  display: none;
  //TABLET
  @media only screen and (max-width: ${props => props.theme.mediaQuery.md}) {
    display: block;
    margin-bottom: 30px;
  }
  
  li{
    position: relative;
    padding-bottom: 15px;
    margin-bottom: 15px;
    
    a{
      display: block;
      font-size: 18px;
    }
    
    .content{
      display: none;
    }
     
    &.open{
      .content{
        display: block;
        > * {
          margin-top: 15px;
        }
        
        a{
          font-size: 14px;
        }
      }
    };
    
    &:before{
      content: '';
      position: absolute;
      left: 0;
      bottom: -1px;
      width: 100%;
      height: 1px;
      background-color: #FFFFFF;
    }
    
    &::after{
      content: '';
      position: absolute;
      top: 7px;
      right: 0;
      width: 0; 
      height: 0;
      border-left: 5px solid transparent;
      border-right: 5px solid transparent;
      border-top: 5px solid #FFFFFF;
    }
    
    
    &:last-of-type{
      padding-bottom: 0;
      margin-bottom: 0;
    
      &:before{
        display: none;
      }
    }
  }   
`;
const Social = styled.ul`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    
    //MOBILE
    @media only screen and (max-width: ${props => props.theme.mediaQuery.sm}) {
      display: block;
      width: auto;
      margin: 0 auto;
    }
    
    li{
      margin: 0 5px;
      @media only screen and (max-width: ${props => props.theme.mediaQuery.sm}) {
        display: inline-block;
      }
    }
`;
const Logos = styled.div`
    margin: 15px 0;
    display: flex;

    .crown {
        display: flex;
        flex-direction: column;
        justify-content: center;
        border-right: 1px solid #fff;
        margin-right: 30px;
        padding-right: 30px;
    }
    @media only screen and (max-width: ${props => props.theme.mediaQuery.sm}) {
        justify-content: center;
    }
`;

const FooterReda = props => {
    const { t } = useTranslation();
    const openMenu = (e) => {
        e.preventDefault();
        const parent = e.target.parentElement;
        if ( parent.className.replace(/[\n\t]/g, ' ').indexOf('open') > -1 ) {
            parent.classList.remove('open');
        } else {
            parent.classList.add('open');
        }
    };

    return (
        <StyledFooter className="container">
            {/* HEADER */}
            <div className="row header">
                <Logos className="col-xs-12 col-sm-6">
                    <NavLink to="/" className="crown">
                        <img src={crown} alt="" height="40"/>
                    </NavLink>
                    <img src={bCorp} alt="" height="65"/>
                </Logos>
                <div className="col-xs-12 col-sm-6 social">
                    <div className="row center-xs end-sm">
                        <div className="col-xs-12 col-sm-9">
                            <Social>
                                <li>
                                    <a target="_blank" rel="noopener noreferrer" href="https://www.youtube.com/channel/UCuOy8ptPdTh86QzHNt2x4Hw" >
                                        <img  src={youtube} alt="" width="40"/>
                                    </a>
                                </li>
                                <li>
                                    <a target="_blank" rel="noopener noreferrer" href="https://www.instagram.com/reda1865/?hl=it">
                                        <img  src={instagram} alt="" width="40"/>
                                    </a>
                                </li>
                                <li>
                                    <a target="_blank" rel="noopener noreferrer" href="https://www.facebook.com/Reda.since.1865/">
                                        <img  src={facebook} alt="" width="40"/>
                                    </a>
                                </li>
                                <li>
                                    <a target="_blank" rel="noopener noreferrer" href="https://www.linkedin.com/organization-guest/company/successori-reda-s-p-a-?originalSubdomain=it&challengeId=AQHNd_NbB7suBAAAAXLv-DjBwddfdqHheYjW-ga00eeUWtm86YrPIEM9iNIcXPh4bOCtKg8Ux7-gnuP-Knd_kIq9RW5c4fAWeQ&submissionId=69dfa141-250e-1c16-ac53-b1cdcc67b133">
                                        <img  src={linkedin} alt="" width="40"/>
                                    </a>
                                </li>
                            </Social>
                        </div>
                    </div>
                </div>
            </div>
            {/* CONTENT */}
            <div className="row between-xs">
                <div className="col-xs-12 col-sm-4 col-md-6">
                    <ul className="menu">
                        <li>
                            <NavLink to="/group" title={t('Footer.group')}>
                                {t('Footer.THE_GROUP')}
                            </NavLink>
                        </li>
                        <li>
                            <NavLink to="/customer-service" title={t('Footer.customerService')}>
                                {t('Footer.customerService')}
                            </NavLink>
                        </li>
                        <li>
                            <a href={i18n.language === 'it' ? ethicCodeIT : ethicCodeEN} download>
                                {t('Footer.ethicCode')}
                            </a>
                        </li>
                        <li>
                            <NavLink to="/legal-area" title="Area Legale">
                                {t('Footer.legalArea')}
                            </NavLink >
                        </li>
                        <li>
                            <NavLink to="/press" title="Area Stampa">
                                {t('Footer.press')}
                            </NavLink>
                        </li>
                        <li>
                            <NavLink to="/contacts" title="Contattaci">
                                {t('Footer.contacts')}
                            </NavLink>
                        </li>
                    </ul>

                    <AccordionMenu>
                        <li>
                            <a href="#" onClick={e => openMenu(e)}>CORPORATE INFO</a>
                            <div className="content">
                                <NavLink to="/group" title={t('Footer.group')}>
                                    {t('Footer.group')}
                                </NavLink>
                                <a href={i18n.language === 'it' ? ethicCodeIT : ethicCodeEN} download>
                                    {t('Footer.ethicCode')}
                                </a>
                                <NavLink to="/press" title="Area Stampa">
                                    {t('Footer.press')}
                                </NavLink>
                            </div>
                        </li>
                        <li>
                            <a href="#" onClick={e => openMenu(e)}>SERVIZIO CLIENTI</a>
                            <div className="content">
                                <NavLink to="/customer-service" title={t('Footer.customerService')}>
                                    {t('Footer.customerService')}
                                </NavLink>
                                <NavLink to="/contacts" title="Contattaci">
                                    {t('Footer.contacts')}
                                </NavLink>
                                <NavLink to="/legal-area" title="Area Legale">
                                    {t('Footer.legalArea')}
                                </NavLink >
                            </div>
                        </li>
                        <li className="col-xs-show">
                            <a href="#" onClick={e => openMenu(e)}>{t('Footer.newLetter.title')}</a>
                            <div className="content">
                                <NewsLetter />
                            </div>
                        </li>
                    </AccordionMenu>
                </div>
                <div className="col-xs-hide col-sm-8 col-md-6">
                    <div className="row end-xs">
                        <div className="col-xs-12 col-sm-9">
                            <NewsLetter />
                        </div>
                    </div>
                </div>
            </div>
            {/*  FOOTER */}
            <div className="row footer">
                <div className="col-xs-12">
                    {t('Footer.address')}
                </div>
            </div>
        </StyledFooter>
    );
};

export default FooterReda;
