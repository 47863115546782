import { getCookie } from '../../helpers/cookie.helper';
import {selectionService} from '../../services/selection.service'
import {history} from '../../helpers/history';

export const SELECTION_TOGGLE_PRODUCT   = 'SELECTION_TOGGLE_PRODUCT';

export const GET_SELECTION_REQUEST  = 'GET_SELECTION_REQUEST';
export const GET_SELECTION_SUCCESS  = 'GET_SELECTION_SUCCESS';
export const GET_SELECTION_FAILURE  = 'GET_SELECTION_FAILURE';

export const ADD_SELECTION_REQUEST  = 'ADD_SELECTION_REQUEST';
export const ADD_SELECTION_SUCCESS  = 'ADD_SELECTION_SUCCESS';
export const ADD_SELECTION_FAILURE  = 'ADD_SELECTION_FAILURE';

export const REMOVE_SELECTION_REQUEST  = 'REMOVE_SELECTION_REQUEST';
export const REMOVE_SELECTION_SUCCESS  = 'REMOVE_SELECTION_SUCCESS';
export const REMOVE_SELECTION_FAILURE  = 'REMOVE_SELECTION_FAILURE';

export const CONFIRM_SELECTION_REQUEST  = 'CONFIRM_SELECTION_REQUEST';
export const CONFIRM_SELECTION_SUCCESS  = 'CONFIRM_SELECTION_SUCCESS';
export const CONFIRM_SELECTION_FAILURE  = 'CONFIRM_SELECTION_FAILURE';

export const SHARE_SELECTION_REQUEST  = 'SHARE_SELECTION_REQUEST';
export const SHARE_SELECTION_SUCCESS  = 'SHARE_SELECTION_SUCCESS';
export const SHARE_SELECTION_FAILURE  = 'SHARE_SELECTION_FAILURE';

export const selectionActions = {
    loadPreferences,
    getPreferences,
    addSelection,
    removeSelection,
    confirmSelection,
    sharePreferences
};

function getPreferences() {
    return async dispatch => {
        dispatch(request());

        const result = await selectionService.getPreferences();
        if (!result.error) {
            dispatch(success(result.data));
        }else{
            const error = { message: result.message };
            dispatch(failure(error));
        }
    };

    function request(data) { return { type: GET_SELECTION_REQUEST, data } }
    function success(data) { return { type: GET_SELECTION_SUCCESS, data } }
    function failure(error) { return { type: GET_SELECTION_FAILURE, error } }
}

function loadPreferences(data) {
    return { type: GET_SELECTION_SUCCESS, data }
}

function addSelection(collection, designs) {
    return async dispatch => {
        dispatch(request());

        const result = await selectionService.addSelection(designs);
        if (!result.error) {
            dispatch(success({designs, collection}));
        }else{
            const error = { message: result.message };
            dispatch(failure(error));
        }
    };

    function request() { return { type: ADD_SELECTION_REQUEST } }
    function success(data) { return { type: ADD_SELECTION_SUCCESS, data } }
    function failure(error) { return { type: ADD_SELECTION_FAILURE, error } }
}

function removeSelection(collection, designs) {
    return async dispatch => {
        dispatch(request());

        const result = await selectionService.removeSelection(designs);
        if (!result.error) {
            dispatch(success({designs, collection}));
        }else{
            const error = { message: result.message };
            dispatch(failure(error));
        }
    };

    function request() { return { type: REMOVE_SELECTION_REQUEST } }
    function success(data) { return { type: REMOVE_SELECTION_SUCCESS, data } }
    function failure(error) { return { type: REMOVE_SELECTION_FAILURE, error } }
}

function confirmSelection() {
    return async dispatch => {
        dispatch(request());

        const salesToken = getCookie('salesManId');
    
        const result = await selectionService.confirmSelection(salesToken ? {
            createdBySalesman: salesToken
        }: {});
        if (!result.error) {
            dispatch(success());
            history.push(`/thank-you`);
        }else{
            const error = { message: result.message };
            dispatch(failure(error));
        }
    };

    function request(data) { return { type: CONFIRM_SELECTION_REQUEST, data } }
    function success() { return { type: CONFIRM_SELECTION_SUCCESS } }
    function failure(error) { return { type: CONFIRM_SELECTION_FAILURE, error } }
}

function sharePreferences(lang, email) {
    return async dispatch => {
        dispatch(request());

        const result = await selectionService.sharePreferences(lang, email);
        if (!result.error) {
            dispatch(success());
        }else{
            const error = { message: result.message };
            dispatch(failure(error));
        }
    };

    function request(data) { return { type: SHARE_SELECTION_REQUEST, data } }
    function success() { return { type: SHARE_SELECTION_SUCCESS } }
    function failure(error) { return { type: SHARE_SELECTION_FAILURE, error } }
}
