import {catalogService} from '../../services/catalog.service';
import {localStorageService} from "../../services/localStorage.service";

export const CATALOG_INIT_STATE    = 'CATALOG_INIT_STATE';

export const CATALOG_PAGE_PRODUCTS_REQUEST    = 'CATALOG_PAGE_PRODUCTS_REQUEST';
export const CATALOG_PAGE_PRODUCTS_SUCCESS    = 'CATALOG_PAGE_PRODUCTS_SUCCESS';
export const CATALOG_PAGE_PRODUCTS_FAILURE    = 'CATALOG_PAGE_PRODUCTS_FAILURE';

export const CATALOG_COMBINE_FILTER = 'CATALOG_COMBINE_FILTER';
export const CATALOG_RESET_ACTIVE_FILTERS = 'CATALOG_RESET_ACTIVE_FILTERS';

export const CATALOG_TOGGLE_VIEW_MODE = 'CATALOG_TOGGLE_VIEW_MODE';

export const CATALOG_SEARCH_FILTERS_ADD = 'CATALOG_SEARCH_FILTERS_ADD';
export const CATALOG_SEARCH_FILTERS_RESET = 'CATALOG_SEARCH_FILTERS_RESET';

export const CATALOG_CHANGE_PAGE = 'CATALOG_CHANGE_PAGE';

export const CATALOG_SET_ACTIVE_SEASON = 'CATALOG_SET_ACTIVE_SEASON'

export const catalogActions = {
    initState,
    getPageProducts,
    combineFilter,
    resetActiveFilters,
    setActiveSeason,
    addSearchFilters,
    resetSearchFilters,

    toggleViewMode,
    changePage
};

//catalog
function initState(brands) {
    return { type: CATALOG_INIT_STATE, data: brands}
}

function getPageProducts(collection, season, filters, activeFilters, currentPage) {
    return async dispatch => {
        dispatch(request({collection}));

        const result = await catalogService.getPageProducts(collection, season);
        if (!result.error) {
            dispatch(success({
                collection,
                products: result.data,
                filters,
                activeFilters,
                currentPage
            }));
        } else {
            const error = { collection, message: result.message };
            dispatch(failure(error));
        }
    };

    function request(data) { return { type: CATALOG_PAGE_PRODUCTS_REQUEST, data } }
    function success(data) { return { type: CATALOG_PAGE_PRODUCTS_SUCCESS, data } }
    function failure(error) { return { type: CATALOG_PAGE_PRODUCTS_FAILURE, error } }
}

function combineFilter(collection, activeFilters, products, filters) {
    const data = {collection, activeFilters, products, filters};
    return { type: CATALOG_COMBINE_FILTER, data };
}

function resetActiveFilters(collection, products, filters, activeFilters) {
    const data = {collection, products, filters, activeFilters};
    return { type: CATALOG_RESET_ACTIVE_FILTERS, data };
}

function addSearchFilters(collection, productFilters, products, filters) {
    const data = {collection, productFilters, products, filters};
    return { type: CATALOG_SEARCH_FILTERS_ADD, data };
}

function resetSearchFilters(collection,  products, filters) {
    const data = {collection, products, filters};
    return { type: CATALOG_SEARCH_FILTERS_RESET, data };
}

function toggleViewMode(viewMode) {
    const data = {viewMode}
    return { type: CATALOG_TOGGLE_VIEW_MODE, data };
}

function changePage(currentPage) {
    const data = {currentPage}
    return { type: CATALOG_CHANGE_PAGE, data };
}

function setActiveSeason(activeSeason) {
    const data = { activeSeason };
    localStorageService.setActiveSeason(activeSeason);
    return { type: CATALOG_SET_ACTIVE_SEASON, data };
}
