import React from 'react';
import {useTranslation} from 'react-i18next';
import './style.scss';
import {Link} from 'react-router-dom';
import LanguageSelector from './LanguageSelector';

const User = (props) => {
    const {t} = useTranslation();
    const {languages, account} = props;

    return (
        <div className="user-component">
            <div className="user">
                <div className="user__title">{t('UserMenu.title')}</div>
                <h6 className="user__subtitle">{t('UserMenu.greeting')} {account.name} {account.surname}</h6>

                    {!account.hasToChangePassword &&
                        <ul className="user__body">
                            <li>
                                <Link to={'/user/personal-data'} className="button" >{t('UserMenu.personalData')}</Link>
                            </li>
                            <li>
                                <Link to={'/user/access-data'} className="button" >{t('UserMenu.accessData')}</Link>
                            </li>
                        </ul>
                    }

                <LanguageSelector languages={languages}/>

                <button onClick={props.logout} className="user__button" >{t('UserMenu.logout')}</button>
            </div>
        </div>
    )
};

export default User;