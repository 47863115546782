import {request} from '../helpers/request';

export const designService = {
    getDesignData
};

// GET Season assets
async function getDesignData(sku, season) {
    return await request.handleRequest('GET', `/assets/${sku}?season=${season}`);
}
