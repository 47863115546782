import React, {useEffect, useRef, useState} from 'react';
import {NavLink, activeClassName, Link} from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import isEmpty from 'lodash.isempty';
import urlSlug from 'url-slug';

import {
    PageNavigation,
    UserNavigation,
    LangMenu,
    NavigationWrapper
} from './style'

const MobileNavigation = (props) => {
    const {brands, languages, changeLanguage, productDesigns, logout} = props;
    const node = useRef();
    const [open, setOpen] = useState(false);
    const { t } = useTranslation();

    useEffect(() => {
        document.addEventListener('mousedown', handleClick);
        return () => {
            document.removeEventListener('mousedown', handleClick);
        };
    }, []);

    const handleClick = (e) => {
        if (node.current.contains(e.target)) return;
        // outside click
        setOpen(false);
    };

    const brandLink = (nav) => {
        const slug = urlSlug(nav.name).toLowerCase();
        return `/current/${slug}`;
    };

    const activeRoute = (match, location, route) => {
        return location.pathname.split('/').indexOf(route.toLowerCase()) !== -1;
    };

    const toggle = (e) => {
        e.preventDefault();
        setOpen(!open);
    };

    const changePage = () => (setOpen(false));

    return (
        <NavigationWrapper ref={node}>
            <a href="#" onClick={e => toggle(e)} className="col-xs-show menu-icon" >
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 19.73 66.84">
                    <path className="path"
                          d="M9.86,3.78a6.41,6.41,0,0,1,2.45.48,6.49,6.49,0,0,1,2.08,1.39,6.42,6.42,0,0,1,1.87,4.52,6.42,6.42,0,0,1-1.87,4.52,6.49,6.49,0,0,1-2.08,1.39,6.4,6.4,0,0,1-7-1.39A6.39,6.39,0,0,1,4,7.72,6.26,6.26,0,0,1,5.34,5.65,6.36,6.36,0,0,1,9.86,3.78Z"/>
                    <path
                        d="M9.86,17.92a7.7,7.7,0,0,1-3-.59,7.69,7.69,0,0,1-4.19-4.19,7.61,7.61,0,0,1-.6-3A7.68,7.68,0,0,1,4.39,4.69,7.53,7.53,0,0,1,6.9,3a7.81,7.81,0,0,1,5.93,0,7.74,7.74,0,0,1,2.51,12.64,7.64,7.64,0,0,1-2.51,1.68A7.71,7.71,0,0,1,9.86,17.92Zm0-12.79a4.88,4.88,0,0,0-1.92.39A4.77,4.77,0,0,0,6.31,6.61a4.89,4.89,0,0,0-1.1,1.63,5.09,5.09,0,0,0,0,3.86A5,5,0,0,0,6.3,13.73a5.06,5.06,0,0,0,5.49,1.09,4.77,4.77,0,0,0,1.63-1.09,4.89,4.89,0,0,0,1.1-1.63,5.09,5.09,0,0,0,0-3.86,5,5,0,0,0-1.09-1.63A5,5,0,0,0,9.86,5.13Z"/>
                    <path className="path"
                          d="M9.86,27a6.41,6.41,0,0,1,2.45.48,6.49,6.49,0,0,1,2.08,1.39,6.4,6.4,0,0,1,0,9,6.49,6.49,0,0,1-2.08,1.39,6.4,6.4,0,0,1-7-1.39,6.4,6.4,0,0,1,0-9A6.36,6.36,0,0,1,9.86,27Z"/>
                    <path
                        d="M9.86,41.17a7.7,7.7,0,0,1-3-.59,7.69,7.69,0,0,1-4.19-4.19,7.61,7.61,0,0,1-.6-3,7.68,7.68,0,0,1,2.28-5.48A7.53,7.53,0,0,1,6.9,26.26a7.81,7.81,0,0,1,5.93,0,7.75,7.75,0,0,1-3,14.91Zm0-12.79a4.88,4.88,0,0,0-1.92.39,4.77,4.77,0,0,0-1.63,1.09,4.89,4.89,0,0,0-1.1,1.63,5.09,5.09,0,0,0,0,3.86A5,5,0,0,0,6.3,37a5.06,5.06,0,0,0,5.49,1.09A4.77,4.77,0,0,0,13.42,37a4.89,4.89,0,0,0,1.1-1.63,5.09,5.09,0,0,0,0-3.86,5,5,0,0,0-1.09-1.63,5,5,0,0,0-3.57-1.48Z"/>
                    <path className="path"
                          d="M9.86,50.28a6.41,6.41,0,0,1,2.45.48,6.49,6.49,0,0,1,2.08,1.39,6.4,6.4,0,0,1,0,9,6.49,6.49,0,0,1-2.08,1.39,6.4,6.4,0,0,1-7-1.39,6.4,6.4,0,0,1,0-9,6.36,6.36,0,0,1,4.52-1.87Z"/>
                    <path
                        d="M9.86,64.42a7.7,7.7,0,0,1-3-.59,7.69,7.69,0,0,1-4.19-4.19,7.61,7.61,0,0,1-.6-3,7.68,7.68,0,0,1,2.28-5.48A7.53,7.53,0,0,1,6.9,49.51a7.81,7.81,0,0,1,5.93,0,7.75,7.75,0,0,1-3,14.91Zm0-12.79A4.88,4.88,0,0,0,7.94,52a4.77,4.77,0,0,0-1.63,1.09,4.89,4.89,0,0,0-1.1,1.63,5.09,5.09,0,0,0,0,3.86A5,5,0,0,0,6.3,60.23a5.06,5.06,0,0,0,5.49,1.09,4.77,4.77,0,0,0,1.63-1.09,4.89,4.89,0,0,0,1.1-1.63,5.09,5.09,0,0,0,0-3.86,5,5,0,0,0-1.09-1.63,5,5,0,0,0-3.57-1.48Z"/>
                </svg>
            </a>

            <PageNavigation className={open ? 'showMenu' : ''}>

                <button className="close-menu" onClick={changePage}>&#10005;</button>

                {brands && brands.map( (nav, index) =>
                    <NavLink
                        key={index}
                        to={brandLink(nav)}
                        isActive={(match, location) => activeRoute(match, location, nav.name)}
                        activeClassName="active"
                        title={nav.name}
                        onClick={changePage}
                    >
                        {nav.name}
                    </NavLink >
                )}

                <NavLink to="/my-selection" onClick={changePage} activeClassName="active" title="Orders">
                    {t('Navigation.my-selection')}
                </NavLink >
                <NavLink to="/orders" onClick={changePage} activeClassName="active" title="Orders">
                    {t('Navigation.orders')}
                </NavLink >
                <NavLink to="/contacts" onClick={changePage} activeClassName="active" title="Contacts">
                    {t('Navigation.contact-us')}
                </NavLink >

                <NavLink to="/user/personal-data" onClick={changePage} className="button" >
                    {t('UserMenu.personalData')}
                </NavLink>
                <NavLink to="/user/access-data" onClick={changePage} className="button" >
                    {t('UserMenu.accessData')}
                </NavLink>

                <LangMenu>
                    {languages.map((lang, index) =>
                        <li key={index}>
                            <a onClick={ e => changeLanguage(e, lang)} href="" title="">{lang}</a>
                        </li>
                    )}
                </LangMenu>

                <NavLink to={'/logout'} className="button log-out" onClick={logout}>{t('UserMenu.logout')}</NavLink>

            </PageNavigation>

        </NavigationWrapper>
    )
};

export default MobileNavigation;
