import {
    DESIGN_DATA_FAILURE,
    DESIGN_DATA_REQUEST,
    DESIGN_DATA_SUCCESS,
} from './design.action';
import {initialState} from './initialState';
import {productHelper} from '../../helpers/product.helper';


export function design(state = initialState, action) {
    switch (action.type) {
        // data actions
        case DESIGN_DATA_REQUEST: return {...state, isFetching: true}
        case DESIGN_DATA_SUCCESS: return dataSuccess(state, action)
        case DESIGN_DATA_FAILURE: return {...state, error: action.error, isFetching: false}
        default:
            return state
    }
}

function dataSuccess(state, action) {
    const {sku, products} = action.data;

    const availableProducts = productHelper.getAvailableProducts(products);
    const designs = productHelper.getDesigns(availableProducts);

    const active = availableProducts.find(color => color.sku === sku);
    const availableColors = availableProducts.filter(product => product.productDesignNumber === active.productDesignNumber);
    const missingColors = productHelper.areAllImagesAvailable(availableColors);

    return {
        ...state,
        products: availableProducts,
        designVariants: designs,
        active,
        colors: availableColors,
        missingColors,
        isFetching: false
    }
}
