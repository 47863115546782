import {request} from '../helpers/request';
import {wishlistService} from './wishlist.service';
import {bannerHelper} from '../helpers/banner.helper';
import pluralize from 'pluralize';
import {authenticationService} from "./authentication.service";

export const appService = {
    init
};

//GET app brands
async function init() {

    let [pagesResult, seasons, translationsResult, preferences, wishlist] = await Promise.all([
        request.handleRequest('GET', `/page-configuration`), // pages
        request.handleRequest('GET', `/validity-periods`), // seasons
        request.handleRequest('POST', `/product-basic-field/list`), // transcode info
        request.handleRequest('GET', `/preferences`), // my selection info
        wishlistService.getList() // wishlist
    ]);

    if (pagesResult.error || seasons.error || translationsResult.error || preferences.error || wishlist.error) {
        authenticationService.logout();
        return {
            error: true
        };
    }

    // TODO spostare la parte di logica nel reducer di competenza
    const translations = translationsResult?.data ? translationsResult.data.reduce((acc, translation) => {
        acc[`${pluralize.singular(translation.productBasicHeader.name).toLowerCase()}_${translation.value.toLowerCase()}`] = {
            it: translation.itDescription,
            en: translation.enDescription,
            key: translation.value.toLowerCase(),
            type: translation.productBasicHeader.name.toLowerCase(),
            banner: translation.banner ? bannerHelper.normalize(translation.banner) : null,
            icon: translation.iconUri
        }
        return acc;
    }, {}) : {};

    // force to lowercase
    const brands = pagesResult?.data?.map(page => {
        page.name = page.name.toLowerCase();
        return page;
    });

    return {
        data: {
            brands,
            translations,
            preferences: preferences.data,
            wishlist: wishlist.data,
            seasons
        }
    }
}
