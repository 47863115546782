import {filterHelper} from '../../helpers/filter.helper';
import {catalogInitialState, initialState} from './initialState';
import urlSlug from 'url-slug';

import {
    CATALOG_CHANGE_PAGE,
    CATALOG_INIT_STATE,
    CATALOG_PAGE_PRODUCTS_FAILURE,
    CATALOG_PAGE_PRODUCTS_REQUEST,
    CATALOG_PAGE_PRODUCTS_SUCCESS,
    CATALOG_RESET_ACTIVE_FILTERS,
    CATALOG_SEARCH_FILTERS_ADD,
    CATALOG_SEARCH_FILTERS_RESET,
    CATALOG_COMBINE_FILTER,
    CATALOG_TOGGLE_VIEW_MODE,
    CATALOG_SET_ACTIVE_SEASON,
} from './catalog.action';
import {productHelper} from '../../helpers/product.helper';
import {articleHelper} from '../../helpers/article.helper';
import {designHelper} from '../../helpers/design.helper';

export function catalog(state = initialState, action) {
    switch (action.type) {
        //init
        case CATALOG_INIT_STATE: return initState(state, action)
        // data actions
        case CATALOG_PAGE_PRODUCTS_REQUEST: return pageProductRequest(state)
        case CATALOG_PAGE_PRODUCTS_SUCCESS: return pageProductSuccess(state, action)
        case CATALOG_PAGE_PRODUCTS_FAILURE: return pageProductFailure(state, action)
        // filters actions
        case CATALOG_COMBINE_FILTER: return combineFilter(state, action)
        case CATALOG_RESET_ACTIVE_FILTERS: return resetActiveFilters(state, action)
        // change the catalog view mode
        case CATALOG_TOGGLE_VIEW_MODE: return toggleViewMode(state, action)
        // pagination
        case CATALOG_CHANGE_PAGE: return changePage(state, action)
        // search
        case CATALOG_SEARCH_FILTERS_ADD: return searchFiltersAdd(state, action)
        case CATALOG_SEARCH_FILTERS_RESET: return searchFiltersReset(state, action)
        // change selected season
        case CATALOG_SET_ACTIVE_SEASON: return setActiveSeason(state, action)
        default:
            return state
    }
}

function initState(state, action) {
    const initialState = { ...state, loaded: true };
    action.data && action.data.forEach(brand => initialState[urlSlug(brand.name).toLowerCase()] = catalogInitialState);
    return initialState;
}

function getCatalogItems(filteredProduct) {
    const articlesList = productHelper.convertProductToArticle(filteredProduct);
    const articlesSort = articleHelper.sortArticleByArticleNumber(articlesList);
    const designsList = productHelper.convertProductToDesign(filteredProduct);
    const designsSort = designHelper.sortDesignByDesignNumber(designsList);

    return {
        articles: {
            list: articlesList,
            sort: articlesSort
        },
        designs: {
            list: designsList,
            sort: designsSort
        },
    }
}

// toggle the catalog visualization
function toggleViewMode (state, action) {
    const {viewMode} = action.data;

    return {
        ...state,
        viewMode,
        currentPage: 0
    };
}

function pageProductRequest (state) {
    return {
        ...state,
        activeFilters: null,
        activeSearch: null,
        currentPage: 0,
        error: null,
        isFetching: true
    }
}

function pageProductSuccess (state, action) {
    const {filters, products, activeFilters, collection, currentPage} = action.data;

    const availableProduct = productHelper.getAvailableProducts(products);
    //filters
    const filteredProduct = filterHelper.filterProductByAttributes(availableProduct, activeFilters);
    const filtersValue = filterHelper.getFilterList(filters, filteredProduct);

    // get catalog items
    const catalogItems = getCatalogItems(filteredProduct);

    return {
        ...state,
        activeFilters,
        [collection]: {
            ...state[collection],
            products: availableProduct,
            articles: catalogItems.articles,
            designs: catalogItems.designs,
            filters: filtersValue
        },
        currentPage: currentPage,
        error: null,
        isFetching: false
    }
}

function pageProductFailure (state, action) {
    return {
        ...state,
        [action.error.collection]: {
            products: [],
            articles: {},
            designs: {},
            filters: {}
        },
        activeFilters: null,
        currentPage: 0,
        error: action.error?.message,
        isFetching: true
    }
}

// filter article and design lists based on the active filters - update the active filters
function combineFilter (state, action) {
    const {collection, activeFilters, products, filters} = action.data;
    const filteredProduct = filterHelper.filterProductByAttributes(products, activeFilters);
    const filtersValue = filterHelper.getFilterList(filters, filteredProduct);
    // get catalog items
    const catalogItems = getCatalogItems(filteredProduct);
    return {
        ...state,
        [collection]: {
            ...state[collection],
            articles: catalogItems.articles,
            designs: catalogItems.designs,
            filters: filtersValue
        },
        currentPage: 0,
        activeSearch: null,
        activeFilters
    }
}

// remove all the sidebar filters and recalculate articles and designs
function resetActiveFilters (state, action) {
    const {collection, products, filters, activeFilters} = action.data;
    const filteredProduct = filterHelper.filterProductByAttributes(products, activeFilters);
    const filtersValue = filterHelper.getFilterList(filters, filteredProduct);
    // get catalog items
    const catalogItems = getCatalogItems(filteredProduct);
    return {
        ...state,
        [collection]: {
            ...state[collection],
            articles: catalogItems.articles,
            designs: catalogItems.designs,
            filters: filtersValue
        },
        activeFilters: null
    };
}

// filter article and design lists based on the active filters - update the active filters
function searchFiltersAdd (state, action) {
    const {collection, productFilters, products, filters} = action.data;

    const filteredProduct = filterHelper.filterProductBySearchAttributes(products, filterHelper.filterValidAttributes, productFilters);
    const filtersValue = filterHelper.getFilterList(filters, filteredProduct);
    // get catalog items
    const catalogItems = getCatalogItems(filteredProduct);

    return {
        ...state,
        [collection]: {
            ...state[collection],
            articles: catalogItems.articles,
            designs: catalogItems.designs,
            filters: filtersValue
        },
        currentPage: 0,
        activeFilters: null,
        activeSearch: productFilters
    }
}

// reset search value
function searchFiltersReset (state, action) {
    const {collection, products, filters} = action.data;

    const filtersValue = filterHelper.getFilterList(filters, products);
    // get catalog items
    const catalogItems = getCatalogItems(products);

    return {
        ...state,
        [collection]: {
            ...state[collection],
            articles: catalogItems.articles,
            designs: catalogItems.designs,
            filters: filtersValue
        },
        currentPage: 0,
        activeSearch: null
    };
}

// pagination
function changePage (state, action) {
    const {currentPage} = action.data;
    return {
        ...state,
        currentPage
    }
}

function setActiveSeason (state, action) {
    const {activeSeason} = action.data;
    return {
        ...state,
        activeSeason
    }
}
