import {request} from '../helpers/request';

export const userService = {
    edit,
    changeEmail,
    changePassword,
    newsLetterRegistration
};

//update account data
async function edit(formData) {
    return await request.handleRequest('PUT', `/account`, formData);
}
//update account email
async function changeEmail(formData) {
    return await request.handleRequest('PUT', `/account/change-email`, formData);
}
//update account password
async function changePassword(formData) {
    return await request.handleRequest('PUT', `/account/change-password`, formData);
}
//send newsLetter registration
async function newsLetterRegistration(formData) {
    return await request.handleRequest('POST', `/newsletter`, formData);
}
