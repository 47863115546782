import styled from 'styled-components';

export const PageNavigation = styled.nav`
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 500;
  height: 100%;
  width: 100%;
  padding-top: 120px;
  padding-left: 30px;
  flex-direction: column;
  
  background: #000;
  
  &.showMenu{
    display: flex;
  }
  
  .close-menu {
    position: absolute;
    top: 20px;
    right: 20px;
    color: #FFFFFF;
    font-size:25px
  }
  
  section{
    position: relative;
    width: 60%;
    padding-bottom: 15px;
    margin-bottom: 17px;
    
    &:before{
      content: '';
      position: absolute;
      left: 0;
      bottom: -1px;
      width: 100%;
      height: 1px;
      background-color: #FFFFFF;
    }
    
    &::after{
      content: '';
      position: absolute;
      top: 3px;
      right: 0;
      width: 0; 
      height: 0;
      border-left: 5px solid transparent;
      border-right: 5px solid transparent;
      border-top: 5px solid #FFFFFF;
    }
    
    a{
      width: 100%;
    }
    
    .brands{
      display: none;
      padding-left: 8px;
      &.open{
        display: block;
      }
      
      li{
        position: relative;
        
        &.active{
          &::after{
            content: '';
            position: absolute;
            top: 12px;
            right: 0;
            width: 0; 
            height: 0;
            border-left: 5px solid transparent;
            border-right: 5px solid transparent;
            border-top: 5px solid #FFFFFF;
          }
        }
      }
      
      span{
        font-size: 14px;
        text-transform: capitalize;
        font-weight: normal;
        color: #FFFFFF;
        display: inline-block;
        text-align: left;
        width: 100%;
        margin: 8px 8px 4px;
      }
      .collection{
        display: none;
        padding-left: 16px;
        
        li{
          margin-bottom: 4px;
        }
        
        a{
          font-size: 14px;
          text-transform: capitalize;
          font-weight: normal;
          color: #FFFFFF;
          display: inline-block;
          text-align: left;
          width: 100%;
        }
        
        &.open{
          display: block;
        }
      }
    }
  }
    
  a{
    position: relative;
    display: block;
    width: 60%;
    font-size: 14px;
    padding: 0 0 15px 0;
    margin-bottom: 17px;
    text-transform: uppercase;
    text-align: left;
    white-space: nowrap;
    transition: font-size .3s;
    line-height: 14px;
    color: #FFFFFF;
    
    &:before{
      content: '';
      position: absolute;
      left: 0;
      bottom: -1px;
      width: 100%;
      height: 1px;
      background-color: #FFFFFF;
    }
    
    &:last-of-type{
      padding-bottom: 0;
      margin-bottom: 0;
    
      &:before{
        display: none;
      }
    }
        
    &:hover{
      font-weight: 500;
    }
        
    &.active{
      font-weight: 500;
      font-size: 16px;
      line-height: 16px;
    }
  }
`;

export const LangMenu = styled.ul`
  display: flex;
  li{
    position: relative;
    display: inline-block;
    margin-right: 15px;
    
    &:after{
      content: '/';
      position: absolute;
      right: -10px;
      top: 0;
      color: #FFFFFF;
    }
    
    &:last-of-type {
      &:after{
        content: '';
      }
    }
    
    a{
      position: relative;
      display: inline-block;
      text-transform: uppercase;
      color: #FFFFFF;
      text-align: left;
      
      .active, &:hover{
        &:after{
          content: '';
          position: absolute;
          left: 0;
          bottom: -1px;
          width: 100%;
          height: 1px;
          background-color: #FFFFFF;
        }
      }
    }
  }
  margin-bottom: 30px;
`;

export const NavigationWrapper = styled.div`
  position: relative;
  
  .menu-icon{
    z-index: 600;
    margin-top: 40px;
    /*
    position: absolute;
    left: 25px;*/
    
    svg{
      width: 25px;
      height: 25px;
    }
    .path{
      fill: #FFFFFF !important;
      stroke: #000000 !important;
    }
  }
`;
