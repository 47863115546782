import React from 'react';
import { NavLink, activeClassName  } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import './style.scss';
import urlSlug from 'url-slug';

const Navigation = (props) => {
    const {t} = useTranslation();
    const {season, brands} = props

    const brandLink = (nav) => {
        const slug = urlSlug(nav.name).toLowerCase();
        return `/${season}/${slug}`;
    };

    const activeRoute = (match, location, route) => {
        return location.pathname.split('/').indexOf(route.toLowerCase()) !== -1;
    };

    return (
        <nav className="top-navigation">
            {brands && brands.map( (nav, index) =>
                <NavLink
                    key={index}
                    to={brandLink(nav)}
                    isActive={(match, location) => activeRoute(match, location, nav.name)}
                    activeClassName="active"
                    title={nav.name}
                    data-text={nav.name}
                >
                    {nav.name}
                </NavLink >
            )}
            <NavLink  to="/my-selection" activeClassName="active" title="My selection" data-text={t('Navigation.my-selection')}>
                {t('Navigation.my-selection')}
            </NavLink >
            <NavLink  to="/orders" activeClassName="active" title="Orders" data-text={t('Navigation.orders')}>
                {t('Navigation.orders')}
            </NavLink >
            <NavLink  to="/contacts" activeClassName="active" title="Contacts" data-text={t('Navigation.contact-us')}>
                {t('Navigation.contact-us')}
            </NavLink >
        </nav>
    )
};

export default Navigation;
