export const MODAL_SHOW = 'MODAL_SHOW';
export const MODAL_HIDE = 'MODAL_HIDE';
export const MODAL_UPDATE_STATUS = 'MODAL_UPDATE_STATUS';

export const modalActions = {
    showModal,
    hideModal,
    updateModal
};

function showModal(translation, callback) {
    const data = {translation: translation, callback: callback};
    return { type: MODAL_SHOW, data };
}
function hideModal() {
    return { type: MODAL_HIDE };
}
function updateModal() {
    const data = {action: true};
    return { type: MODAL_UPDATE_STATUS, data };
}
