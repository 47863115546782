import {
    BRANDS_DATA_CLEAR,
    BRANDS_DATA_FAILURE,
    BRANDS_DATA_REQUEST,
    BRANDS_DATA_SUCCESS,
    BRANDS_LOADED,
} from './brand.action';

import {initialState} from './initialState';

export function brand(state = initialState, action) {
    switch (action.type) {
        case BRANDS_DATA_REQUEST:
            return {...state, isFetching: true};
        case BRANDS_DATA_SUCCESS:
            getDataSuccess(state, action)
        case BRANDS_DATA_FAILURE:
            return {...state, error: action.error, isFetching: false};

        case BRANDS_LOADED:

            if (action.data) {
                return Object.assign({}, state, {
                    results: action.data.map(brand => {
                        const attributeFilters = [...brand.filters.attributeFilters.filter(filter => filter.order), ...brand.filters.attributeFilters.filter(filter => !filter.order)];
                        const categoryFilters = [...brand.filters.categoryFilters.filter(filter => filter.order), ...brand.filters.categoryFilters.filter(filter => !filter.order)];
                        const worldFilters = [...brand.filters.worldFilters.filter(filter => filter.order), ...brand.filters.worldFilters.filter(filter => !filter.order)];
                        const fabricFilters = [...brand.filters.fabricFilters.filter(filter => filter.order), ...brand.filters.fabricFilters.filter(filter => !filter.order)];
                        return {
                            ...brand,
                            filters: attributeFilters.map(filter => filter.header.toLowerCase()),
                            categories: categoryFilters.map(filter => filter.field.toLowerCase()),
                            worlds: worldFilters.map(filter => filter.field.toLowerCase()),
                            fabrics: fabricFilters.map(filter => filter.field.toLowerCase())
                        };
                    }),
                    loaded: true
                })
            } else {
                return {};
            }


        case BRANDS_DATA_CLEAR:
            return {...initialState}

        default:
            return state
    }
}


function formatBrandData(action) {
    console.log('getDataSuccess action', action);

    return action.data.map(brand => {
        const attributeFilters = [
            ...brand.filters.attributeFilters.filter(filter => filter.order),
            ...brand.filters.attributeFilters.filter(filter => !filter.order)
        ];
        const categoryFilters = [
            ...brand.filters.categoryFilters.filter(filter => filter.order),
            ...brand.filters.categoryFilters.filter(filter => !filter.order)
        ];
        const worldFilters = [
            ...brand.filters.worldFilters.filter(filter => filter.order),
            ...brand.filters.worldFilters.filter(filter => !filter.order)
        ];
        const fabricFilters = [
            ...brand.filters.fabricFilters.filter(filter => filter.order),
            ...brand.filters.fabricFilters.filter(filter => !filter.order)
        ];

        return {
            ...brand,
            filters: attributeFilters.map(filter => filter.header.toLowerCase()),
            categories: categoryFilters.map(filter => filter.field.toLowerCase()),
            worlds: worldFilters.map(filter => filter.field.toLowerCase()),
            fabrics: fabricFilters.map(filter => filter.field.toLowerCase())
        }
    });
}

function initDataSuccess(state, action) {

    console.log('results', formatBrandData(action));
    return {
        ...state,
        loaded: true,
        results: formatBrandData(action)
    }
}

function getDataSuccess(state, action) {
    return {
        ...state,
        isFetching: true,
        results: formatBrandData(action)
    }
}
