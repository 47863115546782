import {
    ORDER_REQUEST,
    ORDER_SUCCESS,
    ORDER_FAILURE,
    ORDER_SHARE_REQUEST,
    ORDER_SHARE_SUCCESS,
    ORDER_SHARE_FAILURE
} from './order.action'

const initialState = {
    list: [],
    isFetching: false
};

export function order(state = initialState, action) {
    switch (action.type) {
        case ORDER_REQUEST:
            return Object.assign({}, state, {
                list: [],
                isFetching: true
            });
        case ORDER_SUCCESS:
            return Object.assign({}, state, {
                list: action.data,
                isFetching: false
            });
        case ORDER_FAILURE:
            return Object.assign({}, state, {
                list: [],
                isFetching: false
            });
        case ORDER_SHARE_REQUEST:
            return Object.assign({}, state, {
                isFetching: true
            });
        case ORDER_SHARE_SUCCESS:
            return Object.assign({}, state, {
                isFetching: false
            });
        case ORDER_SHARE_FAILURE:
            return Object.assign({}, state, {
                isFetching: false
            });
        default:
            return state
    }
}
