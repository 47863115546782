import {request} from '../helpers/request';

export const wishlistService = {
    getList,
    addToWishlist,
    removeFromWishlist,
    moveToSelection
}


function getList() {
    return request.handleRequest('GET', `/wishlist/list`);
}

//add designs to the wishlist
function addToWishlist(id, designs) {
    const wishlistData = {
        id,
        productDesignNumbers: designs
    }
    return request.handleRequest('POST', `/wishlist`, wishlistData);
}

//remove designs to the wishlist
function removeFromWishlist(id, designs) {
    const wishlistData = {
        id,
        productDesignNumbers: designs
    }
    return request.handleRequest('DELETE', `/wishlist`, {data: wishlistData});
}

function moveToSelection(designWishlists) {
    return request.handleRequest('POST', `/wishlist/add-preference`, {'wishlists': designWishlists});
}