import {
    CONFIRM_REQUEST,
    CONFIRM_SUCCESS,
    CONFIRM_FAILURE,
    CONFIRM_CURRENCY_REQUEST,
    CONFIRM_CURRENCY_SUCCESS,
    CONFIRM_CURRENCY_FAILURE,
    CONFIRM_ACCOUNT_REQUEST,
    CONFIRM_ACCOUNT_SUCCESS,
    CONFIRM_ACCOUNT_FAILURE,
    CONFIRM_SUPPORT_REQUEST,
    CONFIRM_SUPPORT_SUCCESS,
    CONFIRM_SUPPORT_FAILURE
} from './confirm.action'

const initialState = {
    areaManagers: [],
    customerSupports: [],
    currency: [],
    account: {},
    isFetching: false
};

export function confirm(state = initialState, action) {
    switch (action.type) {
        case CONFIRM_REQUEST:
            return Object.assign({}, state, {
                isFetching: true
            });
        case CONFIRM_SUCCESS:
            return Object.assign({}, state, {
                isFetching: false
            });
        case CONFIRM_FAILURE:
            return Object.assign({}, state, {
                isFetching: false
            });
        case CONFIRM_CURRENCY_REQUEST:
            return Object.assign({}, state, {
                currency: [],
                isFetching: true
            });
        case CONFIRM_CURRENCY_SUCCESS:
            return Object.assign({}, state, {
                currency: action.data,
                isFetching: false
            });
        case CONFIRM_CURRENCY_FAILURE:
            return Object.assign({}, state, {
                currency: [],
                isFetching: false
            });
        case CONFIRM_ACCOUNT_REQUEST:
            return Object.assign({}, state, {
                account: {},
                isFetching: true
            });
        case CONFIRM_ACCOUNT_SUCCESS:
            return Object.assign({}, state, {
                account: action.data,
                isFetching: false
            });
        case CONFIRM_ACCOUNT_FAILURE:
            return Object.assign({}, state, {
                account: {},
                isFetching: false
            });
        case CONFIRM_SUPPORT_REQUEST:
            return Object.assign({}, state, {
                areaManagers:[],
                customerSupports: [],
                isFetching: true
            });
        case CONFIRM_SUPPORT_SUCCESS:
            return Object.assign({}, state, {
                support: action.data,
                areaManagers: action.data.areaManagers,
                customerSupports: action.data.customerSupports,
                isFetching: false
            });
        case CONFIRM_SUPPORT_FAILURE:
            return Object.assign({}, state, {
                areaManagers:[],
                customerSupports: [],
                isFetching: false
            });
        default:
            return state
    }
}
