import React from 'react';

import './style.scss'
function Modal(props) {
    const {visible, children, text, closeModal, callback} = props

    return (
        <div className="modal-component">
            {visible &&
                <div className="mask">
                    <div className="modal">
                        <div className="modal__header">
                            <div className="modal__header__title">{text.title}</div>
                            <a className="modal__header__close" href="#" onClick={closeModal}> &#10005; </a>
                        </div>
                        <div className="modal__body">
                            {children}
                        </div>
                        <div className="modal__footer">
                            <button className="button" onClick={closeModal}>
                                {text.cancel}
                            </button>
                            <button className="button" onClick={callback}>
                                {text.confirm}
                            </button>
                        </div>
                    </div>
                </div>
            }
        </div>
    )
}
export default Modal;
