import {request} from '../helpers/request';

export const supportService = {
    supportRequest
};

//SHARE user selection
async function supportRequest(formData) {
    return await request.handleRequest('POST', `/support-requests`, formData);
}
