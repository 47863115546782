import * as enTranslations from "./en";
import * as itTranslations from "./it";
import {initReactI18next} from 'react-i18next';
import i18next from "i18next";
const i18n = i18next/*.use(LanguageDetector)*/.use(initReactI18next);
const resources = {
    en: {translations: enTranslations},
    it: {translations: itTranslations}
};
// i18n.on('initialized', (options) => {
//     for (let language of i18n.languages) {
//         if (i18n.options.supportedLngs.indexOf(language) !== -1) {
//             i18n.changeLanguage(language);
//             return;
//         }
//     }
// });
i18n.init({
    react: {
        wait: true,
    },
    resources: resources,
    // lng: navigator.language.substring(0, 2), // Sbagliato perchè prence la lng del browser e non dell'utente
    fallbackLng: 'it',
    keySeparator: '.',
    interpolation: {
        escapeValue: false,
    },
    ns: ['translations'],
    defaultNS: 'translations',
    fallbackNS: [],
    supportedLngs: ['en', 'it'],
    nonExplicitSupportedLngs: true
});

export default i18n;
