import React from 'react';
import styled from 'styled-components'
import {NavLink} from "react-router-dom";
import { useTranslation } from 'react-i18next';


const StyledUserMenu = styled.div`
  font-size: 18px;
  li {
    font-size: 16px;
    margin-bottom: 1em;
    margin-left: 1.5em;
    &:hover {
      cursor: pointer;
    }
    
    a{
      span{
        display: inline-block;
        position: relative;
      }
        
      &:hover, &.active {
        span{
          &:after{
            content: '';
            position: absolute;
            left: 0;
            bottom: -1px;
            width: 100%;
            height: 1px;
            background-color: #000000;
          }
        }
      }
    }
  }
`;

const UserMenu = props => {
    const { t } = useTranslation();

    return(
        <StyledUserMenu>
            <ul>
                <li>
                    <NavLink to={'/user/personal-data'} activeClassName="active">
                        <span>{t('User.menu.personalData')}</span>
                    </NavLink>
                </li>
                <li>
                    <NavLink to={'/user/access-data'} activeClassName="active">
                        <span>{t('User.menu.accessData')}</span>
                    </NavLink>
                </li>
            </ul>
        </StyledUserMenu>
    )
};

export default UserMenu;
