import React from 'react';
import {compose} from 'redux';
import {connect} from 'react-redux';
import {withTranslation} from 'react-i18next';
import styled, {createGlobalStyle} from 'styled-components';

//components
import UserMenu from '../User/UserMenu';

const GlobalStyle = createGlobalStyle`
  html, body, #root{ 
    height: 100%; 
    margin: 0;
    font-size: 16px;
  }
  .col-sm-hide{
    //TABLET
    @media only screen and (max-width: ${props => props.theme.mediaQuery.md}) {
      display: none;
    }
  }
  .col-sm-show{
    display: none;
    //TABLET
    @media only screen and (max-width: ${props => props.theme.mediaQuery.md}) {
      display: initial;
    }
  }
  
  .col-xs-hide{
    @media only screen and (max-width: ${props => props.theme.mediaQuery.sm}) {
      display: none;
    }
  }
  .col-xs-show{
    display: none;
    @media only screen and (max-width: ${props => props.theme.mediaQuery.sm}) {
      display: block;
    }
  }
  
`;

const StyledUser = styled.div`
    h1 {
      font-size: 18px;
      font-weight: 500;
      text-transform: uppercase;
      margin-bottom: 45px;
      //TABLET
      @media only screen and (max-width: ${props => props.theme.mediaQuery.md}) {
        text-align: center;
      }
      
      //MOBILE
      @media only screen and (max-width: ${props => props.theme.mediaQuery.sm}) {
        display: none;
      }
    }
`;

class _UserLayout extends React.Component {
    render() {
        const {t, children} = this.props;
        return(
            <StyledUser>
                <GlobalStyle />
                <div className="container">
                    <h1>{t('User.title')}</h1>

                    <div className="row">
                        <div className="col-sm-3 col-sm-hide">
                            <UserMenu />
                        </div>
                        <div className="col-xs-offset-1 col-xs-10 col-md-8">
                            {children}
                        </div>
                    </div>
                </div>
            </StyledUser>
        );
    }
}

function mapState(state) {
    return {};
}

const actionCreators = {};

const UserLayout = compose(withTranslation(), connect(mapState, actionCreators))(_UserLayout);
export default UserLayout;
