import {request} from '../helpers/request';

export const resetPasswordService = {
    resetPassword
};

//POST reset password form
async function resetPassword(form) {
    return await request.handleRequest('POST', `/account/reset-password`, form);
}
