import urlSlug from 'url-slug';
import isEmpty from 'lodash.isempty';

// get all the available products
const getAvailableProducts = (products) => {
    // get only the visible product
    return products.filter(product => !isEmpty(product.images));
}

// check if at least one product has unavailable images
const areAllImagesAvailable = (products) => {
    return !!products.find(product => !isEmpty(product.images));
}

const getTissueImage =  (product, thumb = false) => {
    const imageType = thumb ? 'thumbnailImages' : 'images';
    let image = product[imageType].find(image => image.uri.indexOf('_A') !== -1)

    return image?.uri;
}

const groupProductByPage = (products) => {
    return products.reduce((accumulator, product) => {
        const page = urlSlug(product.page).toLowerCase();

        accumulator[page] = !accumulator[page] ? [] : accumulator[page];
        accumulator[page].push(product);
        return accumulator;
    }, {});
}

// convert a list of products in a design
const convertProductToDesign = (products) => {
    return products.reduce((accumulator, product) => {
        const design = product.productDesignNumber;
        //add design
        accumulator[design] = !accumulator[design] ? [] : accumulator[design];
        //add color variant
        accumulator[design].push(product);

        return accumulator;
    }, {});
}

// convert a list of products in an article
const convertProductToArticle = (products) => {
    return products.reduce((accumulator, product) => {
        const article = product.productArticleNumber.toUpperCase();
        const design = product.productDesignNumber;
        //add product
        accumulator[article] = !accumulator[article] ? {} : accumulator[article];
        //add design
        accumulator[article][design] = !accumulator[article][design] ? [] : accumulator[article][design];
        //add color variant
        accumulator[article][design].push(product);

        return accumulator;
    }, {});
}

const getDesigns = (products, category = null, world = null) => {
    return products
        .filter(product =>
            (!category || product.categories.indexOf(category) !== -1) &&
            (!world || product.worlds.indexOf(world) !== -1)
        )
        .reduce((obj, product) => {
            const design = product.productDesignNumber;
            //add design
            obj[design] = !obj[design] ? [] : obj[design];
            //add color variant
            if(!isEmpty(product.images)) obj[design].push(product);
            if(isEmpty(obj[design])) delete obj[design];

            return obj;
    }, {});
};

const isDesignInSelection = (variant, selection) => {
    const mainCollection    = urlSlug(variant.page).toLowerCase(),
          collection        = urlSlug(variant.collection).toLowerCase(),
          design            = variant.productDesignNumber;

    if(Object.keys(selection).findIndex(item => item === mainCollection) === -1) return false;
    if(Object.keys(selection[mainCollection]).findIndex(item => item === collection) === -1) return false;
    if(Object.keys(selection[mainCollection][collection]).findIndex(item => item === design) === -1) return false;

    return true;
};

// TODO ordinare anche per productColorNumber
const getSortedVariants = (variants) => {
    return variants.sort((variantA, variantB) => variantA.priority < variantB.priority ? -1 : variantA.priority > variantB.priority ? 1 : 0)
};

const getFirstPriorityVariant = (variants) => {
    return variants.sort((variantA, variantB) => variantA.priority < variantB.priority ? -1 : variantA.priority > variantB.priority ? 1 : 0)[0]
};

const getSortedDesigns = (designs) => {
    return Object.keys(designs).sort((designA, designB) => {
        const variantA  = designs[designA].sort((variantA, variantB) => variantA.priority < variantB.priority ? -1 : variantA.priority > variantB.priority ? 1 : 0)[0];
        const variantB  = designs[designB].sort((variantA, variantB) => variantA.priority < variantB.priority ? -1 : variantA.priority > variantB.priority ? 1 : 0)[0];

        return variantA.priority < variantB.priority ? -1 : variantA.priority > variantB.priority ? 1 : 0;
    });
};

const getProductCollections = (products) => {
    return products.reduce((accumulator, product) => {
        accumulator = accumulator.indexOf(product.collection) === -1 ? [...accumulator, product.collection] : accumulator;
        return accumulator;
    }, [])
}

export const productHelper = {
    groupProductByPage,
    getDesigns,
    isDesignInSelection,
    getSortedVariants,
    getFirstPriorityVariant,
    getSortedDesigns,
    getProductCollections,

    getAvailableProducts,
    areAllImagesAvailable,
    getTissueImage,
    convertProductToDesign,
    convertProductToArticle
};
