import {request} from '../helpers/request';

export const selectionService = {
    getPreferences,
    addSelection,
    removeSelection,
    confirmSelection,
    sharePreferences
};

//prepare data for the server
const formatData = (data) => {
    const skus = {skus : []};
    Object.keys(data).forEach(design => {
        data[design].forEach(product => skus['skus'].push(product.sku));
    });
    return skus;
};

//GET user selections preference
async function getPreferences() {
    return await request.handleRequest('GET', `/preferences`);
}

//ADD product to my selection
async function addSelection(data) {
    const formData = formatData(data);
    return await request.handleRequest('PUT', '/preferences', formData);
}

//REMOVE products from my selection
async function removeSelection(data) {
    const formData = formatData(data);
    return await request.handleRequest('DELETE', '/preferences', {data: formData});
}

//CONFIRM user selection
async function confirmSelection(data = {}) {
    return await request.handleRequest('POST', '/my-selections', data);
}
//SHARE user selection
async function sharePreferences(lang, email) {
    const formData = {
        'emails': [email]
    };
    return await request.handleRequest('POST', `/preferences/${lang}/share-pdf`, formData);
}
