import {appService} from "../../services/app.service";
import {brandActions} from "../brand/brand.action";
import {translationActions} from "../translations/translations.action";
import {catalogActions} from "../catalog/catalog.action";
import {selectionActions} from '../selection/selection.action';
import {wishlistActions} from '../wishlist/wishlist.action';
import {seasonActions} from "../season/season.action";

export const INIT_APP  = 'INIT_APP';
export const INIT_APP_SUCCESS  = 'INIT_APP_SUCCESS';
export const INIT_APP_FAILURE  = 'INIT_APP_FAILURE';

export const appActions = {
    init
}

function init() {
    return async dispatch => {

        dispatch(request());
        const result = await appService.init();

        if (!result.error) {
            dispatch(catalogActions.initState(result.data.brands));
            dispatch(brandActions.brandsLoaded(result.data.brands));
            dispatch(translationActions.translationsLoaded(result.data.translations));
            // dispatch(iconActions.iconsLoaded(result.data.icons));

            dispatch(selectionActions.loadPreferences(result.data.preferences))
            dispatch(wishlistActions.loadList(result.data.wishlist))
            dispatch(seasonActions.seasonLoaded(result.data.seasons))

            dispatch(success());
        } else {
            dispatch(failure({ message: result.message }));
        }

        function request() { return { type: INIT_APP } }
        function success() { return { type: INIT_APP_SUCCESS } }
        function failure(error) { return { type: INIT_APP_FAILURE, error } }
    }
}
