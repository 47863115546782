import {
    // ACCOUNT_REQUEST,
    // ACCOUNT_SUCCESS,
    // ACCOUNT_FAILURE,
    // EDIT_PERSONAL_DATA_REQUEST,
    // EDIT_PERSONAL_DATA_SUCCESS,
    // EDIT_PERSONAL_DATA_FAILURE,
    // CHANGE_EMAIL_REQUEST,
    // CHANGE_EMAIL_SUCCESS,
    // CHANGE_EMAIL_FAILURE,
    // CHANGE_PASSWORD_REQUEST,
    // CHANGE_PASSWORD_SUCCESS,
    // CHANGE_PASSWORD_FAILURE,
    NEWSLETTER_REQUEST,
    NEWSLETTER_SUCCESS,
    NEWSLETTER_FAILURE,
    VIDEO_FINISHED,
    VIDEO_REWATCH

} from './user.action'
import {initialState} from './initialState';
// import {UserRole} from '../authentication/types';

export function user(state = initialState, action) {
    switch (action.type) {
        // case ACCOUNT_REQUEST:
        //     return {
        //         ...state,
        //         account: {
        //             role: UserRole.UNAUTHORIZED,
        //             hasToChangePassword: false
        //         },
        //         isFetching: true
        //     }
        // case ACCOUNT_SUCCESS:
        //     return {
        //         ...state,
        //         account: {
        //             ...action.data,
        //             role: UserRole.CLIENT
        //         },
        //         isFetching: false
        //     }
        // case ACCOUNT_FAILURE:
        //     return {
        //         ...state,
        //         account: {
        //             role: UserRole.UNAUTHORIZED,
        //             hasToChangePassword: false
        //         },
        //         isFetching: false
        //     }
        //
        // case EDIT_PERSONAL_DATA_REQUEST:
        //     return Object.assign({}, state, {
        //         isFetching: true
        //     });
        // case EDIT_PERSONAL_DATA_SUCCESS:
        //     return Object.assign({}, state, {
        //         account: action.data,
        //         isFetching: false
        //     });
        // case EDIT_PERSONAL_DATA_FAILURE:
        //     return Object.assign({}, state, {
        //         isFetching: true
        //     });
        //
        // case CHANGE_EMAIL_REQUEST:
        //     return Object.assign({}, state, {
        //         isFetching: true
        //     });
        // case CHANGE_EMAIL_SUCCESS:
        //     return Object.assign({}, state, {
        //         account: {...state.account, ...action.data},
        //         isFetching: false
        //     });
        // case CHANGE_EMAIL_FAILURE:
        //     return Object.assign({}, state, {
        //         isFetching: false
        //     });
        //
        // case CHANGE_PASSWORD_REQUEST:
        //     return Object.assign({}, state, {
        //         isFetching: true
        //     });
        // case CHANGE_PASSWORD_SUCCESS:
        //     return Object.assign({}, state, {
        //         isFetching: false
        //     });
        // case CHANGE_PASSWORD_FAILURE:
        //     return Object.assign({}, state, {
        //         isFetching: false
        //     });

        case NEWSLETTER_REQUEST:
            return Object.assign({}, state, {
                isFetching: true
            });
        case NEWSLETTER_SUCCESS:
            return Object.assign({}, state, {
                isFetching: false
            });
        case NEWSLETTER_FAILURE:
            return Object.assign({}, state, {
                isFetching: false
            });
        case VIDEO_FINISHED:
            state.seenVideos.push(action.video);
            return state;
        case VIDEO_REWATCH:
            const index = state.seenVideos.findIndex(video => video === action.video);
            if(index > -1) {
                state.seenVideos.splice(index, 1);
            }
            return state;
        default:
            return state
    }
}
