import React from 'react';
import styled, {ThemeProvider, createGlobalStyle} from 'styled-components'

//asset
import logoReda from '../../assets/reda/logo-white.png';
import logoCerruti from '../../assets/cerruti/logo-white.png';

import backgroundReda from '../../assets/reda/background/login-v4.jpg';
import backgroundCerruti from '../../assets/cerruti/background/login.jpg';

import {Link, withRouter} from 'react-router-dom';
import Toasts from "../../components/Toast/Toasts";

const theme = {
    mediaQuery: {
        sm: '48em',
        md: '64em',
        lg: '75em'
    },
    colors: {
        page: '#000000',
        text: '#FFFFFF',
        title: '#FFFFFF',
        link: '#FFFFFF',
        button: {
            color: '#FFFFFF',
            background: '#000000',
            border:'#FFFFFF'
        }
    },
    fontSizes:{
        text: '1em',
        title: '1.8em',
        button: '1em',
        link: '1em'
    }

};
const GlobalStyle = createGlobalStyle`
  html, body, #root{ 
    height: 100%; 
    margin: 0;
    font-size: 14px;
  }
    
  .col-sm-hide{
    @media only screen and (max-width: ${props => props.theme.mediaQuery.md}) {
      display: none;
    }
  }
  .col-sm-show{
    display: none;
    @media only screen and (max-width: ${props => props.theme.mediaQuery.md}) {
      display: block;
    }
  }
    
  .col-xs-hide{
    @media only screen and (max-width: ${props => props.theme.mediaQuery.sm}) {
      display: none;
    }
  }
  .col-xs-show{
    display: none;
    @media only screen and (max-width: ${props => props.theme.mediaQuery.sm}) {
      display: block;
    }
  }
  
  .link{
    position: relative;
    color: ${theme.colors.link};
    font-size: ${theme.fontSizes.link};
    
    &:after{
      content: '';
      position: absolute;
      left: 0;
      bottom: -1px;
      width: 100%;
      height: 1px;
      background-color: ${theme.colors.link};
    }
  }
  
  .button{
    display: inline-block;
    padding: 20px 32px;
    margin: 20px 0 25px;
    font-size: ${props => props.theme.fontSizes.button};
    background-color: ${props => props.theme.colors.button.background};
    text-transform: uppercase;
    color: ${props => props.theme.colors.button.color};
    border: 1px solid ${props => props.theme.colors.button.borderColor};
    transition: background-color .5s ease-out;
    
    &:hover{
      background-color: #FFFFFF;
      color: #000000;
    }
  }
  
  .error-wrapper{
    margin-bottom: 30px;
  }
`;

const StyledPage = styled.div`
  .background {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: -1;
    &.image{
      background: url("${getBackground()}") no-repeat center center fixed; 
      -webkit-background-size: cover;
      -moz-background-size: cover;
      -o-background-size: cover;
      background-size: cover;
    }
    &.mono{
      background-color: ${theme.colors.page};
    }
  }
  
  //TABLET
  @media only screen and (max-width: ${props => props.theme.mediaQuery.md}) {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  
  min-height: 100%;
  padding: 28px 0 28px 0;
`;
const Logo = styled.img`
  padding-top: 45px;
  height: ${process.env.REACT_APP_COMPANY === 'reda' ? '125px' : '200px'};
  
  //MOBILE
  @media only screen and (max-width: ${props => props.theme.mediaQuery.sm}) {
    padding-top: 0;
    height: 54px;
  }
`;

function getBackground() {
    switch (process.env.REACT_APP_COMPANY) {
        case 'reda': return backgroundReda;
        case 'cerruti': return backgroundCerruti;
    }
}

class _AuthLayout extends React.Component {
    render() {
        const getLogo = () => {
            switch (process.env.REACT_APP_COMPANY) {
                case 'reda': return logoReda;
                case 'cerruti': return logoCerruti;
            }
        }
        const pageClass = this.props.location.pathname === '/login' || this.props.location.pathname === '/reset-password' ? 'image' : 'mono';
        return(
            <ThemeProvider theme={theme}>
                <GlobalStyle />
                <StyledPage>
                    <div className={`background ${pageClass}`}></div>
                    <div className="container-fluid">
                        <div className="row center-xs">
                            <div className="col-xs-12">
                                <Link to="/login">
                                    <Logo src={getLogo()} alt="REDA" />
                                </Link>
                            </div>
                        </div>
                    </div>

                    {this.props.children}
                </StyledPage>
                <Toasts />
            </ThemeProvider>
        );
    }
}

const AuthLayout = withRouter(_AuthLayout);
export default AuthLayout;
