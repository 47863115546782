import {
    REGISTER_REQUEST,
    REGISTER_SUCCESS,
    REGISTER_FAILURE
} from './registration.action'

const initialState = {
    isFetching: false
};

export function registration(state = initialState, action) {
    switch (action.type) {
        case REGISTER_REQUEST:
            return Object.assign({}, state, {
                isFetching: true
            });
        case REGISTER_SUCCESS:
            return Object.assign({}, state, {
                isFetching: false
            });
        case REGISTER_FAILURE:
            return Object.assign({}, state, {
                isFetching: false
            });
        default:
            return state
    }
}
