import {wishlistService} from '../../services/wishlist.service';

export const WISHLIST_LIST_REQUEST  = 'WISHLIST_LIST_REQUEST';
export const WISHLIST_LIST_SUCCESS  = 'WISHLIST_LIST_SUCCESS';
export const WISHLIST_LIST_FAILURE  = 'WISHLIST_LIST_FAILURE';

export const WISHLIST_ADD_REQUEST  = 'WISHLIST_ADD_REQUEST';
export const WISHLIST_ADD_SUCCESS  = 'WISHLIST_ADD_SUCCESS';
export const WISHLIST_ADD_FAILURE  = 'WISHLIST_ADD_FAILURE';

export const WISHLIST_REMOVE_REQUEST  = 'WISHLIST_REMOVE_REQUEST';
export const WISHLIST_REMOVE_SUCCESS  = 'WISHLIST_REMOVE_SUCCESS';
export const WISHLIST_REMOVE_FAILURE  = 'WISHLIST_REMOVE_FAILURE';

export const WISHLIST_MOVE_REQUEST = 'WISHLIST_MOVE_REQUEST';
export const WISHLIST_MOVE_SUCCESS = 'WISHLIST_MOVE_SUCCESS';
export const WISHLIST_MOVE_FAILURE = 'WISHLIST_MOVE_FAILURE';


export const wishlistActions = {
    loadList,
    getList,
    addToWishlist,
    removeFromWishlist,
    moveToSelection
}

function loadList(data) {
    return { type: WISHLIST_LIST_SUCCESS, data }
}

function getList() {
    return async dispatch => {
        dispatch(request());

        const result = await wishlistService.getList();
        if (!result.error) {
            dispatch(success(result.data));
        }else{
            const error = { message: result.message };
            dispatch(failure(error));
        }
    };

    function request() { return { type: WISHLIST_LIST_REQUEST } }
    function success(data) { return { type: WISHLIST_LIST_SUCCESS, data } }
    function failure(error) { return { type: WISHLIST_LIST_FAILURE, error } }
}

function addToWishlist(id, designs, products) {
    return async dispatch => {
        dispatch(request());

        const result = await wishlistService.addToWishlist(id, designs);
        if (!result.error) {
            dispatch(success({
                id: result.data.id,
                designs: result.data.productDesignNumbers,
                products
            }));
        }else{
            const error = { message: result.message };
            dispatch(failure(error));
        }
    };

    function request() { return { type: WISHLIST_ADD_REQUEST } }
    function success(data) { return { type: WISHLIST_ADD_SUCCESS, data } }
    function failure(error) { return { type: WISHLIST_ADD_FAILURE, error } }
}

function removeFromWishlist(id, designs) {
    return async dispatch => {
        dispatch(request());

        const result = await wishlistService.removeFromWishlist(id, designs);
        if (!result.error) {
            dispatch(success({id, designs}));
        }else{
            const error = { message: result.message };
            dispatch(failure(error));
        }
    };

    function request() { return { type: WISHLIST_REMOVE_REQUEST } }
    function success(data) { return { type: WISHLIST_REMOVE_SUCCESS, data } }
    function failure(error) { return { type: WISHLIST_REMOVE_FAILURE, error } }
}

function moveToSelection(designWishlists) {
    return async dispatch => {
        dispatch(request());

        const result = await wishlistService.moveToSelection(designWishlists);
        if (!result.error) {
            dispatch(success(result.data));
        }else{
            const error = { message: result.message };
            dispatch(failure(error));
        }
    };

    function request() { return { type: WISHLIST_MOVE_REQUEST } }
    function success(data) { return { type: WISHLIST_MOVE_SUCCESS, data } }
    function failure(error) { return { type: WISHLIST_MOVE_FAILURE, error } }
}
