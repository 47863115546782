import isEmpty from 'lodash.isempty';

const isSelected = (articleDesigns, mySelection) => {
    if (!mySelection || isEmpty(mySelection)) return false
    return Object.keys(articleDesigns).every(design => mySelection.includes(design))
}

const sortArticleByArticleNumber = (articles) => {
    return Object.keys(articles).sort((keyA, keyB) => {
            const keyALength = keyA.length === 3 || keyA.length === 5 ? keyA.length+1 : keyA.length;
            const keyBLength = keyB.length === 3 || keyB.length === 5 ? keyB.length+1 : keyB.length;
            if(keyALength === keyBLength) {
                return parseInt(keyA) > parseInt(keyB) ? 1 : parseInt(keyA) < parseInt(keyB) ? -1 : 0;
            } else {
                return keyBLength - keyALength;
            }
        });
}

export const articleHelper = {
    isSelected,
    sortArticleByArticleNumber
}