import React from 'react';
import { useForm } from 'react-hook-form';
import Form from '../../../styles/Form'
import { useTranslation } from 'react-i18next';

export default function NewsLetterForm ({formSubmit, isFetching}) {
    const { register, errors, handleSubmit } = useForm();
    const { t } = useTranslation();

    return (
        <Form onSubmit={handleSubmit(formSubmit)}>
            <div className="form-group">
                <label>{t('Footer.newLetter.label')}</label>
                <input
                    name="email"
                    type="text"
                    autoComplete="off"
                    ref={register({
                        required: 'Email is required',
                        pattern: {
                            value: /\S+@\S+\.\S+/,
                            message: 'Email is not valid'
                        }
                    })}
                />
                {errors.email && <span className="form-error">{errors.email.message}</span>}
            </div>
            <div className="form-group split">
                <label className="disclaimer">
                    <input type="checkbox" name="privacy" ref={register({ required: t('Footer.newLetter.acceptPrivacyPolicy') })} />
                    {t('Footer.newLetter.disclaimer')}
                </label>
                <button disabled={isFetching}>{t('Footer.newLetter.action')}</button>
                {errors.privacy && <span className="form-error" style={{bottom: '-0.5em'}}>{errors.privacy.message}</span>}
            </div>
        </Form>
    );
};
