export const ERROR_SET  = 'ERROR_SET';
export const ERROR_CLEAR = 'ERROR_CLEAR';

export function setError(error) {
    return {
        type: ERROR_SET,
        message: error
    }
}

export function clearError() {
    return { type: ERROR_CLEAR }
}
