import {
    // LOGIN
    AUTHENTICATION_LOGIN_REQUEST,
    AUTHENTICATION_LOGIN_SUCCESS,
    AUTHENTICATION_LOGIN_FAILURE,
    AUTHENTICATION_LOGOUT,
    // ACCOUNT
    AUTHENTICATION_ACCOUNT_REQUEST,
    AUTHENTICATION_ACCOUNT_SUCCESS,
    AUTHENTICATION_ACCOUNT_FAILURE,

    AUTHENTICATION_EDIT_PERSONAL_DATA_REQUEST,
    AUTHENTICATION_EDIT_PERSONAL_DATA_SUCCESS,
    AUTHENTICATION_EDIT_PERSONAL_DATA_FAILURE,

    AUTHENTICATION_CHANGE_EMAIL_REQUEST,
    AUTHENTICATION_CHANGE_EMAIL_SUCCESS,
    AUTHENTICATION_CHANGE_EMAIL_FAILURE,

    AUTHENTICATION_CHANGE_PASSWORD_REQUEST,
    AUTHENTICATION_CHANGE_PASSWORD_SUCCESS,
    AUTHENTICATION_CHANGE_PASSWORD_FAILURE, AUTHENTICATION_RESET_ERRORS,

} from './authentication.action';
import {UserRole} from './types';
import {initialState} from './initialState';


export function authentication(state = initialState, action) {
    switch (action.type) {
        // RESET ERRORS
        case AUTHENTICATION_RESET_ERRORS:
            return {
                ...state,
                error: ''
            };

        // LOGIN
        case AUTHENTICATION_LOGIN_REQUEST:
            return {
                ...state,
                accessToken: '',
                isFetching: true,
                error: ''
            };
        case AUTHENTICATION_LOGIN_SUCCESS:
            return {
                ...state,
                accessToken: action.data.accessToken,
                isFetching: false,
                error: ''
            };
        case AUTHENTICATION_LOGIN_FAILURE:
            return {
                ...state,
                accessToken: '',
                isFetching: false,
                error: action.error
            };

        // LOGOUT
        case AUTHENTICATION_LOGOUT:
            return {
                ...state,
                role: UserRole.UNAUTHORIZED,
                account: {},
                accessToken: '',
                isFetching: false,
                error: ''
            };

        // ACCOUNT - get info
        case AUTHENTICATION_ACCOUNT_REQUEST:
            return {
                ...state,
                account: {},
                isFetching: true,
                error: ''
            };
        case AUTHENTICATION_ACCOUNT_SUCCESS:
            return {
                ...state,
                role: UserRole.CLIENT,
                account: {...action.data},
                isFetching: false,
                error: ''
            };
        case AUTHENTICATION_ACCOUNT_FAILURE:
            return {
                ...state,
                role: UserRole.UNAUTHORIZED,
                account: {},
                isFetching: false,
                error: action.error
            };
        // ACCOUNT - edit personal data
        case AUTHENTICATION_EDIT_PERSONAL_DATA_REQUEST:
            return {
                ...state,
                isFetching: true,
                error: ''
            };
        case AUTHENTICATION_EDIT_PERSONAL_DATA_SUCCESS:
            return {
                ...state,
                account: {...action.data},
                isFetching: false,
                error: ''
            };
        case AUTHENTICATION_EDIT_PERSONAL_DATA_FAILURE:
            return {
                ...state,
                isFetching: false,
                error: action.error
            };

        // ACCOUNT - change email
        case AUTHENTICATION_CHANGE_EMAIL_REQUEST:
            return {
                ...state,
                isFetching: true,
                error: ''
            };
        case AUTHENTICATION_CHANGE_EMAIL_SUCCESS:
            return {
                ...state,
                account: {...state.account, ...action.data},
                isFetching: false,
                error: ''
            };
        case AUTHENTICATION_CHANGE_EMAIL_FAILURE:
            return {
                ...state,
                isFetching: false,
                error: action.error
            };

        // ACCOUNT - change password
        case AUTHENTICATION_CHANGE_PASSWORD_REQUEST:
            return {
                ...state,
                isFetching: true,
                error: ''
            };
        case AUTHENTICATION_CHANGE_PASSWORD_SUCCESS:
            return {
                ...state,
                account: action.data,
                isFetching: false,
                error: ''
            };
        case AUTHENTICATION_CHANGE_PASSWORD_FAILURE:
            return {
                ...state,
                isFetching: false,
                error: action.error
            };

        default:
            return state;
    }
}
