import {ICONS_LOADED} from './icons.action'

const initialState = {
    results: [],
    loaded: false
};
export function icon(state = initialState, action) {
    switch (action.type) {
        case ICONS_LOADED:
            return {
                ...state,
                results: action.data,
                loaded: true
            }
        default:
            return state
    }
}
