import React from 'react';
import styled, {ThemeProvider, createGlobalStyle} from 'styled-components'
import MuliFont from '../../assets/fonts/Muli/Muli-Light.ttf';
import Rasmus from '../../assets/fonts/Rasmus/Rasmus-Regular.otf';
import Trueno from '../../assets/fonts/Trueno/TruenoLt.otf';

//components
import Header from '../../components/Header/TopBar';
import SupportButton from '../../components/SupportButton/SupportButton';

import FooterReda from '../../components/Footer/FooterReda';
import FooterCerruti from '../../components/Footer/FooterCerruti';

import Toasts from '../../components/Toast/Toasts';

const theme = {
    mediaQuery: {
        sm: '43em',//'48em',
        md: '64em',
        lg: '75em'
    },
    colors: {
        page: '#000000',
        text: '#FFFFFF',
        title: '#FFFFFF',
        link: '#FFFFFF',
        button: {
            color: '#FFFFFF',
            background: '#000000',
            border:'#FFFFFF'
        }
    },
    fontSizes:{
        text: '1em',
        title: '1.8em',
        button: '1em',
        link: '1em'
    }

};
const GlobalStyle = createGlobalStyle`
  html, body, #root{ 
    height: 100%; 
    margin: 0;
    font-size: 16px;
  }
  
  @font-face {
    font-family: Muli;
    src: url(${MuliFont});
  }

  @font-face {
    font-family: Rasmus;
    src: url(${Rasmus});
  }

  @font-face {
    font-family: Trueno;
    src: url(${Trueno});
  }
  
  .col-sm-hide{
    //TABLET
    @media only screen and (max-width: ${props => props.theme.mediaQuery.md}) {
      display: none;
    }
  }
  .col-sm-show{
    display: none;
    //TABLET
    @media only screen and (max-width: ${props => props.theme.mediaQuery.md}) {
      display: initial;
    }
  }
  
  .col-xs-hide{
    //MOBILE
    @media only screen and (max-width: ${props => props.theme.mediaQuery.sm}) {
      display: none !important;
    }
  }
  .col-xs-show{
    display: none;
    //MOBILE
    @media only screen and (max-width: ${props => props.theme.mediaQuery.sm}) {
      display: block;
    }
  }
  
`;

const App = styled.div`
  padding-top: 147px;
  min-height: 100%;
  margin-bottom: -360px;
  
  //MOBILE
  @media only screen and (max-width: ${props => props.theme.mediaQuery.sm}) {
    min-height: auto;
    padding-top: 105px;
    margin-bottom: 0;
  }
`;
const AppContent = styled.div`
  padding: 45px 0 100px;
  
  //MOBILE
  @media only screen and (max-width: ${props => props.theme.mediaQuery.sm}) {
    padding: 1px 0 50px;
  }

  //MOBILE
  @media only screen and (max-width: ${props => props.theme.mediaQuery.sm}) {
    padding: 0;
  }
`;
const Push = styled.div`
  height: 360px;
  
  //MOBILE
  @media only screen and (max-width: ${props => props.theme.mediaQuery.sm}) {
    display: none;
  }
`;
const FooterWrapper = styled.div`
  background-color: #000000;
`;

class AppLayout extends React.Component {
    render() {

        const getFooter = () => {
            switch (process.env.REACT_APP_COMPANY) {
                case 'reda': return <FooterReda />;
                case 'cerruti':  return <FooterCerruti />;
            }
        }

        return(
            <ThemeProvider theme={theme}>
                <GlobalStyle />
                <App>
                    <Header />
                    <AppContent>
                        {this.props.children}
                    </AppContent>
                    <Push />
                </App>
                {process.env.REACT_APP_COMPANY === 'reda' && <SupportButton />}
                <FooterWrapper>
                    { getFooter() }
                </FooterWrapper>
                <Toasts />
            </ThemeProvider>
        );
    }
}

export default AppLayout;
