import React from 'react';
import { withTranslation } from 'react-i18next';
import styled from 'styled-components'
import {callbackMap} from "@vimeo/player/src/lib/callbacks";

const StyledToast = styled.li`
  z-index: 1050;
  position: fixed;
  top: 1rem;
  right: 1rem;
  min-width: 350px;

  &.no-auth {
      top: 1rem !important;
  }

  -webkit-box-shadow: 0px 7px 7px -4px rgba(0,0,0,1);
  -moz-box-shadow: 0px 7px 7px -4px rgba(0,0,0,1);
  box-shadow: 0px 7px 7px -4px rgba(0,0,0,1);
  border-radius: 10px;
  .content {
    min-height: 40px;
    padding: 15px 30px;
  }
  &.info {
    background-color: #000;
    color: #fff;
  }
  &.success {
    background-color: #4caf50;
    color: #000;
  }
  &.danger {
    background-color: #d32f2f;
    color: #fff;
  }
  .dismiss {
    position: absolute;
    height: 100%;
    width: 50px;
    text-align: center;
    top: 0;
    right: 0;
    opacity: .3;
    &:hover {
      opacity: 1;
    }
  }
`

class Toast extends React.Component {
    getClassName = () => {
        return this.props.type || 'info';
    }

    render() {
        const t = this.props.t;

        return (
            <StyledToast className={this.getClassName()}>
                <p className="content">
                    {t(this.props.text)}
                </p>
                <button className="dismiss" onClick={this.props.onDismissClick}>
                    <i className="fa fa-bell-slash"></i>
                </button>
            </StyledToast>
        );
    }

    shouldComponentUpdate(nextProps, nextState, nextContext) {
        return false;
    }
}

export default withTranslation()(Toast);
