import {
    MODAL_SHOW,
    MODAL_HIDE,
    MODAL_UPDATE_STATUS
} from './modal.action'

const initialState = {
    visible: false,
    action: false,
    callback: null,
    translation: ''
};

export function modal(state = initialState, action) {
    switch (action.type) {
        case MODAL_SHOW:
            return Object.assign({}, state, {
                action: false,
                visible: true,
                callback: action.data.callback,
                translation: action.data.translation
            });
        case MODAL_HIDE:
            return Object.assign({}, state, {
                action: false,
                callback: null,
                visible: false
            });
        case MODAL_UPDATE_STATUS:
            return Object.assign({}, state, {
                action: true,
                visible: false
            });
        default:
            return state
    }
}
