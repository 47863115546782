export const initialState = {
    // all the raw products
    products: [],
    designVariants: {},
    // active color variant
    active: {},
    // active design color variant
    colors: [],
    missingColors: false,
    error: '',
    isFetching: false
}