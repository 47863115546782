import {request} from '../helpers/request';



export const catalogService = {
    getPageProducts
};

/**
 * @param collection
 * @param season:  'old' | 'current' | 'pre'
 * @returns {Promise<*>}
 */
async function getPageProducts(collection, season) {

    return await request.handleRequest('GET', `/assets?page=${collection}&season=${season}`);
}
