import { connect } from 'react-redux';
import Toast from './Toast';
import React from 'react';
import styled from 'styled-components'
import { bindActionCreators } from 'redux';
import { removeToast } from '../../store/toasts/toasts.action';

const StyledToasts = styled.ul`
  position: fixed;
  z-index: 3000;
  bottom: 60px;
  width: 350px;
  left: 50%;
  margin-left: -175px;
`

const Toasts = ({ actions, toasts }) => {
    const { removeToast } = actions;

    const dismissToastFunction = (toast) => {
        removeToast(toast.id);
        if (toast.callback) {
            toast.callback();
        }
    }

    return (
        <StyledToasts className="toasts">
            {toasts.map((toast) => {
                return (
                    <Toast {...toast} key={toast.id} onDismissClick={() => dismissToastFunction(toast)} />
                );
            })}
        </StyledToasts>
    );
}

const mapDispatchToProps = dispatch => ({
    actions: bindActionCreators({ removeToast }, dispatch)
});

const mapStateToProps = state => ({
    toasts: state.toasts
});

export default connect(mapStateToProps, mapDispatchToProps)(Toasts);
