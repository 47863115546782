import {
    RESET_PASSWORD_REQUEST,
    RESET_PASSWORD_SUCCESS,
    RESET_PASSWORD_FAILURE
} from './reset-password.action'

const initialState = {
    isFetching: false
};

export function resetPassword(state = initialState, action) {
    switch (action.type) {
        case RESET_PASSWORD_REQUEST:
            return Object.assign({}, state, {
                isFetching: true
            });
        case RESET_PASSWORD_SUCCESS:
            return Object.assign({}, state, {
                isFetching: false
            });
        case RESET_PASSWORD_FAILURE:
            return Object.assign({}, state, {
                isFetching: false
            });
        default:
            return state
    }
}
