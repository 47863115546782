import styled from 'styled-components';

export const StyledSelection = styled.div`
  position: relative;
  width: 320px;
  
  padding: 10px;
  border: 1px solid #CCCCCC;
  border-radius: 23px;
  background-color: #FFFFFF;
  
  //MOBILE
  @media only screen and (max-width: ${props => props.theme.mediaQuery.sm}) {
    &:not(.open) {
      width: 50px;
    }
    margin: 15px auto;
  }
  
  label {
      display: block;
      padding: 15px 15px;
      border: 1px solid #FFFFFF;
      text-transform: uppercase;
      
      
      font-size: 12px;
      font-weight: 500;
      color: #FFFFFF;
      background-color: #000000;
      transition: background-color .5s ease-out;
      transition: color .5s ease-out;
      text-align: center;
      
  }
  .form-error{
    font-size: 14px;
  }
  
  .textarea-wrapper{
    position: relative;
    padding-bottom: 20px;
    &:after {
      content: " ";
      position: absolute;
      bottom: 0;
      right: 3%;
      height: 0;
      width: 0;
      border: solid transparent;
      pointer-events: none;
      border-width: 10px;
      margin-left: -10px;
      border-top: 10px solid #CCCCCC;
    }
  }
  
  textarea {
    background-color: #fff;
    width: 100%;
    height: 100px;
    min-height: auto;
    resize: none;
    color: #000;
    padding: 15px 15px;
    border: 2px solid #CCC;
    font-size: 12px;
  }
`;

export const Title = styled.h3`
    margin-bottom: 15px;
    font-size: 14px;
    color: #FFFFFF;
    text-align: left;
    position: relative;
    //padding-left: 40px;
    text-transform: uppercase;
    display: flex;
    align-items: center;
    
    //width: calc( 100% + 30px);
    //padding: 0 15px 15px 15px;
    //margin-left: -15px;
    //border-bottom: 1px solid #000;
    
    .img-wrapper{
        width: 45px;
        height: 45px;
        background-color: #000000;
        border-radius: 22px;
        position: relative;
        
        img{
          position: absolute;
          left: 7px;
          top: 12px;
        }
    }
    
    .close{
      color: #000;
      position: absolute;
      top: 11px;
      right: 14px;
      font-weight: 500;
    }
`;

export const Menu = styled.ul`
  display: flex;
  justify-content: space-between;

  li{    
    display: block;
    padding: 15px 0;
    color: #000;
    font-size: 12px;
    text-transform: uppercase;
    
    a{
      display: block;
      border: 1px solid #000000;
      padding: 8px;
      text-align: center;
      
      &.active,&:hover{
        background-color: #000000;
        color: #FFFFFF;
      }
    }
  }
`;

export const Button = styled.a`
  display: flex;
  align-items: baseline;
  width: 70%;
  margin: auto;
  font-size: 12px;
  line-height: 1.4;
  font-weight: 500;
  color: #000000;
  background-color: #FFFFFF;
  transition: background-color .5s ease-out;
  transition: color .5s ease-out;
  
  align-items: center;
  justify-content: space-around;
    
  //border-radius: 30px;
  
  //MOBILE
  @media only screen and (max-width: ${props => props.theme.mediaQuery.sm}) {
    height: 25px;
  }
  
  span{
    display: inline-block;
    //MOBILE
    @media only screen and (max-width: ${props => props.theme.mediaQuery.sm}) {
      display: none;
    }
  }
  
  .livello-1{
    width: 24px;
  }
`;

export const Submit = styled.button`
  display: block;
  width:50%;
  padding: 8px;
  margin: 15px auto 0;
  border: 1px solid #FFFFFF;
  text-transform: uppercase;
  font-size: 12px;
  font-weight: 500;
  color: #FFFFFF;
  background-color: #000000;
  transition: background-color .5s ease-out;
  transition: color .5s ease-out;
  text-align: center;
  
  &:hover{
    background-color: #FFFFFF;
    color: #000000;
    border-color: #000000;
  }
`;

export const SelectionDetailWrapper = styled.div`
  //z-index: 800;
  //position: absolute;
  //right: 0px;
  //bottom: calc(100% + 10px);
  width: 100%;//350px;
  //padding: 15px;
  //background: #000000;
  text-align: left;
  border-radius: 4px;
  
  .content{
    //padding: 15px;
    background-color: #FFFFFF;
  }
  
  color: #FFFFFF;
`;

export const Message = styled.div`
  .label{
    text-transform: capitalize;
    color: #000;
    font-size: 12px;
    font-weight: 500;
  }
  
  .box{
    position: relative;
    padding: 8px;
    margin-top: 16px;
    color: #000000;
    line-height: 1.4;
    font-size: 12px;
    border-radius: 4px;
    
    background-color: #CCCCCC;
    
    &:after {
      bottom: 100%;
      left: 8%;
      border: solid transparent;
      content: " ";
      height: 0;
      width: 0;
      position: absolute;
      pointer-events: none;
      border-bottom-color: #CCCCCC;
      border-width: 10px;
      margin-left: -10px;
    }
    
  }
`;
