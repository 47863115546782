import {
    ADD_SELECTION_FAILURE,
    ADD_SELECTION_REQUEST,
    ADD_SELECTION_SUCCESS,
    CONFIRM_SELECTION_FAILURE,
    CONFIRM_SELECTION_REQUEST,
    CONFIRM_SELECTION_SUCCESS,
    GET_SELECTION_FAILURE,
    GET_SELECTION_REQUEST,
    GET_SELECTION_SUCCESS,
    REMOVE_SELECTION_FAILURE,
    REMOVE_SELECTION_REQUEST,
    REMOVE_SELECTION_SUCCESS,
    SHARE_SELECTION_FAILURE,
    SHARE_SELECTION_REQUEST,
    SHARE_SELECTION_SUCCESS
} from './selection.action';
import {initialState} from './initialState';
import {productHelper} from '../../helpers/product.helper';

export function selection(state = initialState, action) {
    switch (action.type) {
        case ADD_SELECTION_REQUEST: return {...state, isFetching: true};
        case ADD_SELECTION_SUCCESS: return addSelectionSuccess(state, action)
        case ADD_SELECTION_FAILURE: return {...state, isFetching: false};

        case REMOVE_SELECTION_REQUEST: return {...state, isFetching: true};
        case REMOVE_SELECTION_SUCCESS: return removeSelectionSuccess(state, action)
        case REMOVE_SELECTION_FAILURE: return {...state, isFetching: false};

        case GET_SELECTION_REQUEST: return {...state, preferences: [], isFetching: true};
        case GET_SELECTION_SUCCESS: return getSelectionSuccess(state, action)
        case GET_SELECTION_FAILURE: return {...state, preferences: [], isFetching: false};

        case SHARE_SELECTION_REQUEST: return {...state, isFetching: true};
        case SHARE_SELECTION_SUCCESS: return {...state, isFetching: false};
        case SHARE_SELECTION_FAILURE: return {...state, isFetching: false};

        case CONFIRM_SELECTION_REQUEST: return {...state, isFetching: true};
        case CONFIRM_SELECTION_SUCCESS: return {...state, productDesigns: initialState.productDesigns, isFetching: false};
        case CONFIRM_SELECTION_FAILURE: return {...state, isFetching: false};

        default:
            return state
    }
}

function getSelectionSuccess(state, action) {
    const preferenceId = action.data.preferenceId || null;
    const assets = action.data.assets || [];

    let selectedDesigns = productHelper.groupProductByPage(assets)
    selectedDesigns = Object.keys(selectedDesigns).reduce((acc, collection) => {
        // remove duplicates
        acc[collection] = [...new Set(selectedDesigns[collection].map(product => product.productDesignNumber))]
        return acc
    }, {})

    return {
        ...state,
        preferenceId,
        assets,
        selectedDesigns
    }
}

function addSelectionSuccess(state, action) {
    const {designs, collection} = action.data;
    const assetsToAdd = Object.keys(designs).reduce((acc, design) =>
        ([...acc, ...designs[design]]), []
    )
    const collectionDesigns = !state.selectedDesigns[collection] ? [] : state.selectedDesigns[collection];

    return {
        ...state,
        assets: [
            ...state.assets,
            ...assetsToAdd
        ],
        selectedDesigns: {
            ...state.selectedDesigns,
            // remove duplicates
            ...{ [collection]: [...new Set([ ...collectionDesigns, ...Object.keys(designs) ])]}
        }
    }
}

function removeSelectionSuccess(state, action) {
    const {designs, collection} = action.data;

    // retrieve the list of sku's of assets to remove
    const assetsToRemove = Object.keys(designs).reduce((acc, design) =>
        ([...acc, ...designs[design].map(product => product.sku)]), []
    )

    // filter designs, and eliminate empty collections
    const selectedDesigns = {...state.selectedDesigns}
    selectedDesigns[collection] = selectedDesigns[collection].filter(design => Object.keys(designs).indexOf(design) === -1);
    if (!selectedDesigns[collection].length) { delete selectedDesigns[collection]; }

    return {
        ...state,
        assets: state.assets.filter(asset => assetsToRemove.indexOf(asset.sku) === -1),
        selectedDesigns
    }
}
