import isEmpty from 'lodash.isempty';

const getVariant = (products) => {
    const orderedProducts = products
        .sort((variantA, variantB) => {
            return variantA.productColorNumber < variantB.productColorNumber ? -1 : variantA.productColorNumber > variantB.productColorNumber ? 1 : 0
        })
        .sort((variantA, variantB) => {
            return variantA.priority < variantB.priority ? -1 : variantA.priority > variantB.priority ? 1 : 0
        })
    return orderedProducts[0];
}

const isSelected = (variant, mySelection) => {
    if (!mySelection || isEmpty(mySelection) || !variant) return false
    return mySelection.includes(variant.productDesignNumber)
}

const inWishlist = (variant, wishlist) => {
    if (!wishlist || isEmpty(wishlist) || !variant) return false
    return wishlist.includes(variant.productDesignNumber)
}

const getBackgroundImage = (product) => {
    return product.thumbnailImages[0] ? `url("${product.thumbnailImages[0].uri}")` : 'url("")';
}

const sortDesignByDesignNumber = (designs) => {
    return Object.keys(designs).sort((keyA, keyB) => {
        const keyALength = keyA.length === 3 || keyA.length === 5 ? keyA.length+1 : keyA.length;
        const keyBLength = keyB.length === 3 || keyB.length === 5 ? keyB.length+1 : keyB.length;
        if(keyALength === keyBLength) {
            return parseInt(keyA) > parseInt(keyB) ? 1 : parseInt(keyA) < parseInt(keyB) ? -1 : 0;
        } else {
            return keyBLength - keyALength;
        }
    });
}

const getWorldsByDesigns = (designs) => {
    if (!designs || isEmpty(designs)) { return [] }

    return Object.keys(designs).reduce((accumulator, design) => {
        let difference = designs[design][0].worlds.filter(category => !accumulator.includes(category));
        if (difference.length) {
            accumulator = [...accumulator, ...difference]
        }
        return accumulator
    },[])
}

// return the active categories based on the passed designs
const getCategoriesByDesigns = (designs) => {
    if (!designs || isEmpty(designs)) { return [] }

    return Object.keys(designs).reduce((accumulator, design) => {
        let difference = designs[design][0].categories.filter(category => !accumulator.includes(category));
        if (difference.length) {
            accumulator = [...accumulator, ...difference]
        }
        return accumulator
    },[])
}

const getIcon = (icon, category, translations) => {
    if (isEmpty(translations)) return '';

    const index = Object.keys(translations).find(translationKey => `${category}_${icon}` === translationKey);

    return index && translations[index].icon ? translations[index].icon : '';
}

const getFabricByDesigns = (designs) => {
    if (!designs || isEmpty(designs)) { return [] }

    return Object.keys(designs).reduce((accumulator, design) => {
        if (accumulator.indexOf(designs[design][0].type) === -1) {
            accumulator.push(designs[design][0].type);
        }
        return accumulator
    },[])
}

export const designHelper = {
    getVariant,
    isSelected,
    inWishlist,
    getBackgroundImage,
    sortDesignByDesignNumber,
    getWorldsByDesigns,
    getCategoriesByDesigns,
    getFabricByDesigns,
    getIcon
}
