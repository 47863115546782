import React from 'react';
import {useTranslation} from 'react-i18next';
import './style.scss';
import {Link} from 'react-router-dom';
import urlSlug from "url-slug";

const Selection = (props) => {
    const {t} = useTranslation();
    const {productDesigns, brands} = props;

    const getCollectionItems = () => {
        return brands.map(brand => {
            return {
                name: brand.name.charAt(0).toUpperCase() + brand.name.slice(1),
                count: productDesigns[urlSlug(brand.name).toLowerCase()] ? productDesigns[urlSlug(brand.name).toLowerCase()].length : 0
            }
        })
    };
    const collectionItems = getCollectionItems();

    return (
        <div className="selection-component">
            <div className="selection">
                <div className="selection__title">{t('SelectionMenu.title')}</div>
                <ul className="selection__body">
                    {collectionItems.map(collection =>
                        <li key={`selection-${collection.name}`}>
                            <span className="title">{collection.name}</span>
                            <span className="description">
                                {t('SelectionMenu.total', {count: collection.count})}
                            </span>
                        </li>
                    ) }
                </ul>
                <Link to={'/my-selection'} className="selection__button" onClick={props.callback}>{t('SelectionMenu.goToPreference')}</Link>
            </div>
        </div>
    )
};

export default Selection;