import {TRANSLATIONS_LOADED} from './translations.action'

const initialState = {
    results: [],
    loaded: false
};
export function translation(state = initialState, action) {
    switch (action.type) {
        case TRANSLATIONS_LOADED:
            return {
                ...state,
                results: action.data,
                loaded: true
            }
        default:
            return state
    }
}
