import {request} from '../helpers/request';
import {localStorageService} from './localStorage.service';
import qs from 'qs';

export const authenticationService = {
    login,
    logout,
    getAccount
};

async function login(username, password) {
    const formData = qs.stringify({
        username,
        password,
        'grant_type': 'password'
    });
    return await request.handleRequest('POST', '/account/login', formData, 'application/x-www-form-urlencoded');
}

function logout() {
    localStorageService.clearStorage();
}

//get the user account data
async function getAccount() {
    return  await request.handleRequest('GET', '/account/logged');
}
