import React from 'react';
import styled from 'styled-components';
import i18n from '../../../i18n/i18n';

const LangMenu = styled.ul`
   display: flex;
   margin-top: 20px;
  li {
    
    &:after {
      content: '-';
      color: white;
      margin: 0 10px;
    }
    
    &:last-of-type {
      &:after{
        content: '';
      }
    }
    
    a {
      position: relative;
      display: inline-block;
      text-transform: uppercase;
      color: #FFFFFF;
      width: 30px;
      padding: 2px 4px;
      text-align: center;
      &.active {
        border-bottom: 2px solid white;
      }
      &:hover{
        &:after{
          content: '';
          position: absolute;
          left: 0;
          bottom: -1px;
          width: 100%;
          height: 1px;
          background-color: #FFFFFF;
        }
      }
    }
  }
  margin-bottom: 30px;
`;


const LanguageSelector = ({languages}) => {

    const changeLanguage = (e, lang) => {
        e.preventDefault();
        i18n.changeLanguage(lang);
        localStorage.setItem('reda_catalog_current_lang', lang);
    };

    return (
        <LangMenu>
            {languages.map((lang, index) =>
                <li key={index}>
                    <a
                        onClick={e => changeLanguage(e, lang)}
                        href=""
                        title=""
                        className={i18n.language === lang ? 'active' : null}
                    >
                        {lang}
                    </a>
                </li>
            )}
        </LangMenu>
    )
};

export default LanguageSelector;
