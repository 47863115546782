import React from 'react';
import {connect} from 'react-redux';
import {withTranslation} from 'react-i18next';
import isEmpty from 'lodash.isempty';

//helpers
import {history} from '../../helpers/history';
import {productHelper} from '../../helpers/product.helper';
import {sectionHelper} from '../../helpers/section.helper';
//components
import Navigation from '../Navigation/Navigation';
import MobileNavigation from './MobileNavigation/MobileNavigation';
import Selection from './Selection/Selection';
import User from './User/User';
import Wishlist from './Wishlist/Wishlist';
import IconMenu from '../IconMenu/IconMenu';
//assets
import logoReda from '../../assets/reda/logo-black.svg';
import logoCerruti from '../../assets/cerruti/logo-flat.svg';

import {NavLink} from 'react-router-dom';
import { authenticationActions } from '../../store/authentication/authentication.action';

import './style.scss';

class TopBar extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            languages: ['it','en'],
            searchOpened: false
        };
    }

    changeSelection = (brand, collection) => {
        //navigate to the page
        history.push(`/my-selection?brand=${brand}&collection=${collection}`);
    };

    toggleSearch = () => {
        this.setState({searchOpened: !this.state.searchOpened});
    };

    getTotalSelectionItems = (selectedDesigns) => {
        if (!selectedDesigns || isEmpty(selectedDesigns)) { return 0 }
        return Object.keys(selectedDesigns).reduce((amount, collection) =>  (amount += selectedDesigns[collection].length), 0) || 0;
    }

    getWishlistItems = (wishlist) => {
        const {user, suggested} = wishlist;
        let totalAssets = !isEmpty(suggested) ? suggested.reduce((acc, suggested) => (acc = [...acc, ...suggested.assets]), []) : [];
        return !isEmpty(user) ? [...totalAssets, ...user.assets] : totalAssets;
    }

    getLogo = () => {
        switch (process.env.REACT_APP_COMPANY) {
            case 'reda': return logoReda;
            case 'cerruti': return logoCerruti;
        }
    }

    render() {
        const {
            brand,
            selection,
            wishlist,
            account,
            activeSeason
        } = this.props;

        const wishlistTotalItems = this.getWishlistItems(wishlist)
        const wishlistAssetsByPages = productHelper.groupProductByPage(wishlistTotalItems)

        const getWishlistIcon = () => (
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 67.06 64.09" style={{width: "22px", height: "25px"}}>
                <path className="cls-3" d="M65.86,28.28a4,4,0,0,0-2.21-6.81l-17.8-2.58a1,1,0,0,1-.74-.54l-8-16.13a4,4,0,0,0-7.16,0L22,18.35a1,1,0,0,1-.74.54L3.41,21.47A4,4,0,0,0,0,26,4,4,0,0,0,1.2,28.28l13,12.55a1,1,0,0,1,.28.88l-3,17.73A4,4,0,0,0,14.73,64a4.07,4.07,0,0,0,2.5-.39l15.92-8.37a1,1,0,0,1,.92,0L50,63.64a4,4,0,0,0,5.79-4.2l-3-17.73a1,1,0,0,1,.28-.88ZM49.74,42.22l3,17.72a1,1,0,0,1-.9,1.09,1,1,0,0,1-.53-.09L35.43,52.62a4,4,0,0,0-3.72,0L15.79,61a1,1,0,0,1-1.34-.47,1,1,0,0,1-.09-.53l3-17.72a4,4,0,0,0-1.15-3.54L3.37,26.13a1,1,0,0,1-.25-1,1,1,0,0,1,.8-.68l17.8-2.58a4,4,0,0,0,3-2.19l8-16.12a1,1,0,0,1,1.34-.44,1,1,0,0,1,.44.44l8,16.12a4,4,0,0,0,3,2.19l17.8,2.58a1,1,0,0,1,.55,1.68l-13,12.55A4,4,0,0,0,49.74,42.22Z"/>
                <path className="cls-3--fill" d="M65.86,28.28a4,4,0,0,0-2.21-6.81l-17.8-2.58a1,1,0,0,1-.74-.54l-8-16.13a4,4,0,0,0-7.16,0L22,18.35a1,1,0,0,1-.74.54L3.41,21.47A4,4,0,0,0,0,26,4,4,0,0,0,1.2,28.28l13,12.55a1,1,0,0,1,.28.88l-3,17.73A4,4,0,0,0,14.73,64a4.07,4.07,0,0,0,2.5-.39l15.92-8.37a1,1,0,0,1,.92,0L50,63.64a4,4,0,0,0,5.79-4.2l-3-17.73a1,1,0,0,1,.28-.88Z"/>
            </svg>
        )

        const getSelectionIcon = () => (
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 29 34.42" style={{height: "25px"}}>
                <polygon className="cls-1" points="0.75 0.75 28.25 0.75 28.25 32.82 14.49 21.39 0.75 32.82 0.75 0.75"/>
            </svg>
        )

        const getUserIcon = () => (
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24.02 29.37" style={{height: "25px"}}>
                <circle className="cls-2" cx="12.01" cy="6.68" r="6.68"/>
                <path className="cls-2" d="M15.42,15.36H8.6c-4.75,0-8.6,5-8.6,9.74v2.27a2,2,0,0,0,2,2H22a2,2,0,0,0,2-2V25.1C24,20.35,20.17,15.36,15.42,15.36Z"/>
            </svg>
        )

        return (
            <div className="top-bar-component">
                <div className="top-bar container">
                    <div className="top-bar__header">

                        <div className="top-bar__navigation--mobile">
                            <MobileNavigation
                                brands={brand.results}
                                languages={this.state.languages}
                                changeLanguage={this.changeLanguage}
                                productDesigns={selection?.selectedDesigns}
                                changeSelection={this.changeSelection}
                                logout={this.props.logout}
                            />
                        </div>

                        <NavLink to="/home" className="top-bar__header__logo">
                            <div className="logo" style={{background: `url('${this.getLogo()}') no-repeat`}}/>
                        </NavLink>

                        <div className="top-bar__header__actions">
                            {!account.hasToChangePassword &&
                                <React.Fragment>
                                    {/* WISHLIST */}
                                    {sectionHelper.isServiceAvailable('wishlist') && <IconMenu
                                        totalItems={this.getTotalSelectionItems(wishlistAssetsByPages)}
                                    >
                                        {{
                                            icon: getWishlistIcon(),
                                            body:
                                                <Wishlist
                                                    productDesigns={wishlistAssetsByPages}
                                                    brands={brand.results}
                                                />
                                        }}
                                    </IconMenu>}
                                    {/* SELECTION */}
                                    <IconMenu
                                        totalItems={this.getTotalSelectionItems(selection?.selectedDesigns)}
                                    >
                                        {{
                                            icon: getSelectionIcon(),
                                            body:
                                                <Selection
                                                    productDesigns={selection?.selectedDesigns}
                                                    brands={brand.results}
                                                />
                                        }}
                                    </IconMenu>
                                </React.Fragment>
                            }

                            {/* USER */}
                            <div className="user">
                                <IconMenu
                                    lastItem={true}
                                >
                                    {{
                                        icon: getUserIcon(),
                                        body:
                                            <User
                                                account={account}
                                                languages={this.state.languages}
                                                changeLanguage={this.changeLanguage}
                                                logout={this.props.logout}
                                            />
                                    }}
                                </IconMenu>
                            </div>
                        </div>
                    </div>
                    <div className="top-bar__navigation">
                        {
                            !account.hasToChangePassword &&
                            <Navigation
                                season={activeSeason}
                                brands={brand.results}
                            />
                        }
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => ({
    brand: state.brand,
    selection: state.selection,
    wishlist: state.wishlist,
    account: state.authentication.account,
    activeSeason: state.catalog.activeSeason
})

const mapDispatchToProps = dispatch => ({
    logout: () => dispatch(authenticationActions.logout())
})

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(TopBar))
