import {registrationService} from '../../services/registration.service'
import {history} from '../../helpers/history';
import {analyticsHelper} from '../../helpers/analytics.helper';

export const REGISTER_REQUEST   = 'REGISTER_REQUEST';
export const REGISTER_SUCCESS   = 'REGISTER_SUCCESS';
export const REGISTER_FAILURE   = 'REGISTER_FAILURE';

export const registrationActions = {
    register,
};

//REGISTER
function register(form) {
    return async dispatch => {
        dispatch(request({form}));

        const result = await registrationService.register(form);
        if (!result.error) {
            dispatch(success(result.data));
            analyticsHelper.registrationGATrack();
            history.push('/login');
        } else {
            const error = { message: result.message };
            dispatch(failure(error));
        }
    };

    function request(data) { return { type: REGISTER_REQUEST, data } }
    function success(data) { return { type: REGISTER_SUCCESS, data } }
    function failure(error) { return { type: REGISTER_FAILURE, error } }
}
