import 'react-app-polyfill/stable';
import React from 'react';
import ReactDOM from 'react-dom';
import {Provider, useDispatch} from 'react-redux';
import {I18nextProvider} from 'react-i18next';
import smoothscroll from 'smoothscroll-polyfill'


// style
import './styles/form.scss';

//css responsive grid
import 'flexboxgrid/dist/flexboxgrid.css';
//css reset
import 'destyle.css';
//custom style
import './index.scss';
//router
import Router from './components/Router/Router';

//import state
import { store } from './store';
//import translations
import i18n from './i18n/i18n';

import axios from 'axios';
import {localStorageService} from './services/localStorage.service';
//helper
import {history} from './helpers/history';
import {analyticsHelper} from './helpers/analytics.helper';

smoothscroll.polyfill();

// Add a request interceptor
axios.interceptors.request.use(
    config => {
        const token = localStorageService.getAccessToken();
        if (token) {
            config.headers['Authorization'] = `Bearer ${token}`;
        }
        return config;
    }
);

axios.interceptors.response.use(
    (response) => response,
    (error) => {
        // Add a 401 response interceptor
        if (401 === error.response.status) {
            history.push('/login');
            localStorageService.clearToken();
        }
        return Promise.reject(error.response);
    }
);

//init GA tracking
analyticsHelper.initGA();

ReactDOM.render(
    <I18nextProvider i18n={i18n} >
        <Provider store={store} >
            <Router />
        </Provider >
    </I18nextProvider>
    , document.getElementById('root')
);
