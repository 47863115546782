import loadable from '@loadable/component';
import React from 'react';
import {sectionHelper} from '../../helpers/section.helper';

// external pages
const Login = loadable(() => import('../../pages/loginPage'));
const Register = loadable(() => import('../../pages/registrationPage'));
const ResetPassword = loadable(() => import('../../pages/resetPasswordPage'));
// internal pages
const Catalog = loadable(() => import('../Catalog/Catalog'));
const Product = loadable(() => import('../CatalogDetail/CatalogDetail'));
const Order = loadable(() => import('../Order/Order'));
const OrderConfirmation = loadable(() => import('../../components/OrderConfirmation/OrderConfirmation'));
const Selection = loadable(() => import('../Selection/Selection'));
const Wishlist = loadable(() => import('../Wishlist/Wishlist'));
const Contact = loadable(() => import('../Contact/Contact'));
const NotFound = loadable(() => import('../NotFound/NotFound'));

// static pages REDA
const LegalAreaReda = loadable(() => import('../../pages/reda/legalAreaPage'));
const CustomerServiceReda = loadable(() => import('../../pages/reda/customerServicePage'));
const PressReda = loadable(() => import('../../pages/reda/pressPage'));
const GroupReda = loadable(() => import('../../pages/reda/groupPage'));
// static pages CERRUTI
const LegalAreaCerruti = loadable(() => import('../../pages/cerruti/legalAreaPage'));
const CustomerServiceCerruti = loadable(() => import('../../pages/cerruti/customerServicePage'));
const GroupCerruti = loadable(() => import('../../pages/cerruti/groupPage'));

// user
const UserAccessData = loadable(() => import('../User/AccessData'));
const UserPersonalData = loadable(() => import('../User/PersonalData'));
// landing pages LandingPage
const LandingPage = loadable(() => import('../Landing/Landing'));

export const unAuthenticatedRoutes = [
    {
        path: '/login',
        component: Login,
        layout: 'authentication'
    },
    {
        path: '/registration',
        component: Register,
        layout: 'authentication'
    },
    {
        path: '/reset-password',
        component: ResetPassword,
        layout: 'authentication'
    }
];

export const catalogRoutes = [
    {
        path: '/wishlist',
        component: Wishlist, layout: 'app', available: sectionHelper.isServiceAvailable('wishlist')},
    {
        path: '/thank-you',
        component: OrderConfirmation
    },
    {
        path: '/orders',
        component: Order
    },
    {
        path: '/404',
        component: NotFound
    },
    {
        path: '/:season/:collection',
        component: Catalog
    },
    {
        path: '/:season/:collection/:designNumber/:sku',
        component: Product
    },
    {
        path: '/my-selection',
        component: Selection,
        layout: 'app'
    },
    {
        path: '/my-selection/:brand/:collection',
        component: Selection
    },
];

const redaStaticPagesRoutes = [
    {
        path: '/legal-area',
        component: LegalAreaReda
    },
    {
        path: '/customer-service',
        component: CustomerServiceReda
    },
    {
        path: '/press',
        component: PressReda
    },
    {
        path: '/group',
        component: GroupReda
    }
];

const cerrutiStaticPagesRoutes = [
    {
        path: '/legal-area',
        component: LegalAreaCerruti
    },
    {
        path: '/customer-service',
        component: CustomerServiceCerruti
    },
    {
        path: '/group',
        component: GroupCerruti
    }
];

export const staticPagesRoutes = process.env.REACT_APP_COMPANY === 'reda' ?
    [
        {
            path: '/contacts',
            component: Contact
        },
        ...redaStaticPagesRoutes
    ] :
    [
        {
            path: '/contacts',
            component: Contact
        },
        ...cerrutiStaticPagesRoutes
    ];

export const userRoutes = [
    {
        path: '/user/personal-data',
        component: UserPersonalData
    },
    {
        path: '/user/access-data',
        component: UserAccessData
    }
];

export const landingRoutes = [
    {
        path: '/cosmopolitan',
        component: LandingPage,
        page: 'formalwear',
        type: 'cosmopolitan',
        color: 'white',
        available: sectionHelper.isServiceAvailable('landing'),
        active: true
    },
    {
        path: '/maior',
        component: LandingPage,
        page: 'formalwear',
        type: 'maior',
        color: 'white',
        available: sectionHelper.isServiceAvailable('landing'),
        active: true
    },
    {
        path: '/bestretch',
        component: LandingPage,
        page: 'formalwear',
        type: 'bestretch',
        color: 'white',
        available: sectionHelper.isServiceAvailable('landing'),
        active: true
    },
    {
        path: '/back2life',
        component: LandingPage,
        page: 'formalwear',
        type: 'back2life',
        color: 'white',
        available: sectionHelper.isServiceAvailable('landing'),
        active: true
    },
    {
        path: '/winter-edit',
        component: LandingPage,
        page: 'formalwear',
        type: 'winter-edit',
        color: 'white',
        available: sectionHelper.isServiceAvailable('landing'),
        active: true
    },
    {
        path: '/summer-edit',
        component: LandingPage,
        page: 'formalwear',
        type: 'summer-edit',
        color: 'white',
        available: sectionHelper.isServiceAvailable('landing'),
        active: false
    },
    {
        path: '/made-in-nature',
        component: LandingPage,
        page: 'formalwear',
        type: 'made-in-nature',
        color: 'white',
        available: sectionHelper.isServiceAvailable('landing'),
        active: false
    },
    {
        path: '/reda-active',
        component: LandingPage,
        page: 'leisure',
        type: 'active',
        color: 'white',
        available: sectionHelper.isServiceAvailable('landing'),
        active: true
    },
    {
        path: '/attitude',
        component: LandingPage,
        page: 'formalwear',
        type: 'attitude',
        color: 'white',
        available: sectionHelper.isServiceAvailable('landing'),
        active: true
    },
    {
        path: '/flexo',
        component: LandingPage,
        page: 'formalwear',
        type: 'flexo',
        color: 'white',
        available: sectionHelper.isServiceAvailable('landing'),
        active: true
    },
    {
        path: '/cashmere',
        component: LandingPage,
        page: 'formalwear',
        type: 'cashmere',
        color: 'white',
        available: sectionHelper.isServiceAvailable('landing'),
        active: true
    }
];
