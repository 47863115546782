import React from 'react';
import {connect} from 'react-redux';
import {compose} from 'redux';
import {withTranslation} from 'react-i18next';
import styled from 'styled-components';
import urlSlug from 'url-slug';

//actions
import {userActions} from '../../store/user/user.action';
import Form from './NewsLetterForm/NewsLetterForm';

const StyledNewsLetter = styled.div`
  input{
    color: #000000;
  }
  
  .form-group{
    &.split{
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
    }
  }
  
  label{
    font-size: 14px;
    &.disclaimer{
      width: 75%;
      line-height: 1.3;
      font-size: 12px;
    }
  }
  
  button{
    padding: 11px 21px;
    background-color: #000000;
    text-transform: uppercase;
    text-align: center;
    color: #FFFFFF;
    border: 1px solid #FFFFFF;
    
    &:hover{
      background-color: #FFFFFF;
      color: #000000;
    }
  }
`;

const Title = styled.h3`
  display: block;
  margin-bottom: 15px;
  text-transform: uppercase;
  text-align: right;
  font-size: 18px;
  color: #FFFFFF;
  
  //MOBILE
  @media only screen and (max-width: ${props => props.theme.mediaQuery.sm}) {
    display: none
  }
`;

class _NewsLetter extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            requestSuccess: null
        }
    }

    submitForm = (formData) => {
        const data = {
            email: formData.email,
            isItalian: this.isItalian()
        };
        this.props.newsLetterRegistration(data).then(value => {
            this.setState({requestSuccess: value});
            setTimeout(() => {
                this.setState({requestSuccess: null});
            }, 5000);
        });
    };

    isItalian = () => {
      return urlSlug(this.props.account.country).toLowerCase() === 'italia';
    };

    render() {
        const {isFetching, t} = this.props;
        const requestSuccess = this.state.requestSuccess;
        return (
            <StyledNewsLetter>
                <Title>{t('Footer.newLetter.title')}</Title>
                <Form formSubmit={this.submitForm} isFetching={isFetching} />
                {requestSuccess === true && <div style={{textAlign: 'left'}}>{t('Footer.newLetter.success')}</div>}
                {requestSuccess === false && <div style={{textAlign: 'left'}}>{t('Footer.newLetter.failure')}</div>}
            </StyledNewsLetter>
        );
    }
}

function mapState(state) {
    const {account, isFetching} = state.user;
    return {account, isFetching};
}

const actionCreators = {
    newsLetterRegistration: userActions.newsLetterRegistration
};

const NewsLetter = compose(withTranslation(), connect(mapState, actionCreators))(_NewsLetter);
export default NewsLetter;
