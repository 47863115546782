import {
    INIT_APP,
    INIT_APP_SUCCESS,
    INIT_APP_FAILURE
} from './app.action'

const initialState = {
    isFetching: false
};

export function app(state = initialState, action) {
    switch (action.type) {
        case INIT_APP:
            return {
                ...state,
                isFetching: true
            }
        case INIT_APP_SUCCESS:
        case INIT_APP_FAILURE:
            return {
                ...state,
                isFetching: false
            }
        default:
            return state
    }
}
