import {confirmService} from '../../services/confirm.service';

export const CONFIRM_REQUEST   = 'CONFIRM_REQUEST';
export const CONFIRM_SUCCESS   = 'CONFIRM_SUCCESS';
export const CONFIRM_FAILURE   = 'CONFIRM_FAILURE';

export const CONFIRM_CURRENCY_REQUEST   = 'CONFIRM_CURRENCY_REQUEST';
export const CONFIRM_CURRENCY_SUCCESS   = 'CONFIRM_CURRENCY_SUCCESS';
export const CONFIRM_CURRENCY_FAILURE   = 'CONFIRM_CURRENCY_FAILURE';

export const CONFIRM_ACCOUNT_REQUEST    = 'CONFIRM_ACCOUNT_REQUEST';
export const CONFIRM_ACCOUNT_SUCCESS    = 'CONFIRM_ACCOUNT_SUCCESS';
export const CONFIRM_ACCOUNT_FAILURE    = 'CONFIRM_ACCOUNT_FAILURE';

export const CONFIRM_SUPPORT_REQUEST    = 'CONFIRM_SUPPORT_REQUEST';
export const CONFIRM_SUPPORT_SUCCESS    = 'CONFIRM_SUPPORT_SUCCESS';
export const CONFIRM_SUPPORT_FAILURE    = 'CONFIRM_SUPPORT_FAILURE';

export const confirmActions = {
    confirm,
    confirmCurrency,
    confirmAccount,
    support
};

//CONFIRM
function confirm(form) {
    return async dispatch => {
        dispatch(request({form}));

        const result = await confirmService.confirm(form);
        if (!result.error) {
            dispatch(success(result.data));
            return true;
        } else {
            const error = { message: result.message };
            dispatch(failure(error));
            return false;
        }
    };

    function request(data) { return { type: CONFIRM_REQUEST, data } }
    function success(data) { return { type: CONFIRM_SUCCESS, data } }
    function failure(error) { return { type: CONFIRM_FAILURE, error } }
}
//CURRENCY
function confirmCurrency() {
    return async dispatch => {
        dispatch(request());

        const result = await confirmService.getConfirmCurrency();
        if (!result.error) {
            dispatch(success(result.data));
        } else {
            const error = { message: result.message };
            dispatch(failure(error));
        }
    };

    function request(data) { return { type: CONFIRM_CURRENCY_REQUEST, data } }
    function success(data) { return { type: CONFIRM_CURRENCY_SUCCESS, data } }
    function failure(error) { return { type: CONFIRM_CURRENCY_FAILURE, error } }
}
//SUPPORT
function support() {
    return async dispatch => {
        dispatch(request());

        const result = await confirmService.getSupport();
        if (!result.error) {
            dispatch(success(result.data));
        } else {
            const error = { message: result.message };
            dispatch(failure(error));
        }
    };

    function request(data) { return { type: CONFIRM_SUPPORT_REQUEST, data } }
    function success(data) { return { type: CONFIRM_SUPPORT_SUCCESS, data } }
    function failure(error) { return { type: CONFIRM_SUPPORT_FAILURE, error } }
}
//SEND CONFIRMATION
function confirmAccount(id) {
    return async dispatch => {
        dispatch(request());

        const result = await confirmService.getConfirmAccount(id);
        if (!result.error) {
            dispatch(success(result.data));
        } else {
            const error = { message: result.message };
            dispatch(failure(error));
        }
    };

    function request(data) { return { type: CONFIRM_ACCOUNT_REQUEST, data } }
    function success(data) { return { type: CONFIRM_ACCOUNT_SUCCESS, data } }
    function failure(error) { return { type: CONFIRM_ACCOUNT_FAILURE, error } }
}
