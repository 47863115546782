import pluralize from 'pluralize';

const normalize = (banner) => {
    return {
        id: banner.id,
        it: {
            title: banner.titleIt || '',
            description: banner.titleIt || ''
        },
        en: {
            title: banner.titleEn || '',
            description: banner.titleEn || ''
        },
        desktopImageUri: banner.desktopImageUri || null,
        mobileImageUri: banner.mobileImageUri || null,
        videoDesktop: banner.videoDesktop || null,
        videoMobile: banner.videoMobile || null,
        logoImageUri: banner.logoImageUri || null
    }
}

const getBanner = (translations, attribute, label) => {
    label = label.toLowerCase();
    attribute = pluralize.singular(attribute);
    const atrLabel = `${attribute}_${label}`;

    return  translations[atrLabel]?.banner;
}

export const bannerHelper = {
    normalize,
    getBanner
}