import { ERROR_CLEAR } from './error.action';

const initialState = {
    message: null,
    isVisible: false
};

export function error(state = initialState, action) {
    const { error } = action;

    if(error) {
        return {
            message: error.message || 'Error',
            isVisible: true
        }
    } else if(action.type === ERROR_CLEAR) {
        return {
            message: null,
            isVisible: false
        }
    }

    return state
}
