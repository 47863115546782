import { resetPasswordService } from '../../services/reset-password.service';
import {history} from '../../helpers/history';

export const RESET_PASSWORD_REQUEST = '_REQUEST';
export const RESET_PASSWORD_SUCCESS = '_SUCCESS';
export const RESET_PASSWORD_FAILURE = '_FAILURE';

export const resetPasswordActions = {
    resetPassword,
};

//reset
function resetPassword(form) {
    return async dispatch => {
        dispatch(request({form}));

        const result = await resetPasswordService.resetPassword(form);
        if (!result.error) {
            dispatch(success(result.data));
            history.push('/login');
        } else {
            const error = { message: result.message };
            dispatch(failure(error));
        }
    };

    function request(data) { return { type: RESET_PASSWORD_REQUEST, data } }
    function success(data) { return { type: RESET_PASSWORD_SUCCESS, data } }
    function failure(error) { return { type: RESET_PASSWORD_FAILURE, error } }
}
