import ReactGA from 'react-ga';
import isNull from 'lodash.isnull';

const trackingId = process.env.REACT_APP_GA_UA;

// tack events
function event (category, action, label = null, value = null) {
    const eventData = {
        category,
        action,
        label,
        value
    };

    if (isNull(eventData.label)) delete eventData.label;
    if (isNull(eventData.value)) delete eventData.value;

    ReactGA.event(eventData);
}

// init analytics
const initGA = () => ( ReactGA.initialize(trackingId) );

// track page view
const pageViewGATrack = (page) => ( ReactGA.pageview(page) );

// track filter click
const filterGATrack = (selectedFilterType, selectedFilterValue, i18n) => {
    const lang = ['it', 'it-IT'].indexOf(i18n.language) > -1 ? 'it' : 'en';
    i18n.changeLanguage('it-IT');

    const category = 'Filtri';
    const action = `Click su ${selectedFilterType}`;
    const label = selectedFilterValue;

    event(category, action, label);

    i18n.changeLanguage(lang);
}

const loginGATrack = () => {
    const category = 'Utenti';
    const action = 'Login Effettuato';
    event(category, action);
}

const registrationGATrack = () => {
    const category = 'Utenti';
    const action = 'Registrazione Effettuata';
    event(category, action);
}

const nlSubscriptionsGATrack = () => {
    const category = 'Utenti';
    const action = 'NL Registrazione Effettuata';
    event(category, action);
}


export const analyticsHelper = {
    initGA,
    pageViewGATrack,
    filterGATrack,
    registrationGATrack,
    loginGATrack,
    nlSubscriptionsGATrack
};
