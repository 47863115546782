import {authenticationService} from '../../services/authentication.service';
import {userService} from '../../services/user.service';
import {setCookie} from '../../helpers/cookie.helper';

export const AUTHENTICATION_LOGIN_REQUEST  = 'AUTHENTICATION_LOGIN_REQUEST';
export const AUTHENTICATION_LOGIN_SUCCESS  = 'AUTHENTICATION_LOGIN_SUCCESS';
export const AUTHENTICATION_LOGIN_FAILURE  = 'AUTHENTICATION_LOGIN_FAILURE';

export const AUTHENTICATION_ACCOUNT_REQUEST  = 'AUTHENTICATION_ACCOUNT_REQUEST';
export const AUTHENTICATION_ACCOUNT_SUCCESS  = 'AUTHENTICATION_ACCOUNT_SUCCESS';
export const AUTHENTICATION_ACCOUNT_FAILURE  = 'AUTHENTICATION_ACCOUNT_FAILURE';

export const AUTHENTICATION_EDIT_PERSONAL_DATA_REQUEST  = 'AUTHENTICATION_EDIT_PERSONAL_DATA_REQUEST';
export const AUTHENTICATION_EDIT_PERSONAL_DATA_SUCCESS  = 'AUTHENTICATION_EDIT_PERSONAL_DATA_SUCCESS';
export const AUTHENTICATION_EDIT_PERSONAL_DATA_FAILURE  = 'AUTHENTICATION_EDIT_PERSONAL_DATA_FAILURE';

export const AUTHENTICATION_CHANGE_EMAIL_REQUEST  = 'AUTHENTICATION_CHANGE_EMAIL_REQUEST';
export const AUTHENTICATION_CHANGE_EMAIL_SUCCESS  = 'AUTHENTICATION_CHANGE_EMAIL_SUCCESS';
export const AUTHENTICATION_CHANGE_EMAIL_FAILURE  = 'AUTHENTICATION_CHANGE_EMAIL_FAILURE';

export const AUTHENTICATION_CHANGE_PASSWORD_REQUEST  = 'AUTHENTICATION_CHANGE_PASSWORD_REQUEST';
export const AUTHENTICATION_CHANGE_PASSWORD_SUCCESS  = 'AUTHENTICATION_CHANGE_PASSWORD_SUCCESS';
export const AUTHENTICATION_CHANGE_PASSWORD_FAILURE  = 'AUTHENTICATION_CHANGE_PASSWORD_FAILURE';

export const AUTHENTICATION_LOGOUT = 'AUTHENTICATION_LOGOUT';

export const AUTHENTICATION_RESET_ERRORS = 'AUTHENTICATION_RESET_ERRORS';

export const authenticationActions = {
    login,
    logout,
    getAccount,
    editAccount,
    changeAccountEmail,
    changeAccountPassword,
    resetErrors
};

function resetErrors() {
    return { type: AUTHENTICATION_RESET_ERRORS };
}

//LOGIN
function login(userName, password) {
    return async dispatch => {
        dispatch(request());

        const result = await authenticationService.login(userName, password);

        if (!result.error) {
            const accessToken = result.data.access_token;
            dispatch(success({accessToken}));
        }else{
            const error = result.message;
            dispatch(failure(error));
        }
    };

    function request() {return {type: AUTHENTICATION_LOGIN_REQUEST}}
    function success(data) {return {type: AUTHENTICATION_LOGIN_SUCCESS, data}}
    function failure(error) {return {type: AUTHENTICATION_LOGIN_FAILURE, error}}
}

//LOGOUT
function logout() {
    authenticationService.logout();
    return { type: AUTHENTICATION_LOGOUT };
}

function getAccount() {
    return async dispatch => {
        dispatch(request());

        const result = await authenticationService.getAccount();

        if (!result.error) {
            dispatch(success(result.data));

            if(result.data.isSalesman) {
                setCookie('salesManId', result.data.id, 365);
            }
        }else{
            const error = result.message;
            dispatch(failure(error));
        }
    };

    function request() {return {type: AUTHENTICATION_ACCOUNT_REQUEST}}
    function success(data) {return {type: AUTHENTICATION_ACCOUNT_SUCCESS, data}}
    function failure(error) {return {type: AUTHENTICATION_ACCOUNT_FAILURE, error}}
}

// EDIT PERSONAL DATA
function editAccount(formData) {
    return async dispatch => {
        dispatch(request());

        const result = await userService.edit(formData);
        if (!result.error) {
            dispatch(success(formData));
            return true;
        } else {
            const error = result.message;
            dispatch(failure(error));
        }
    };

    function request() {return {type: AUTHENTICATION_EDIT_PERSONAL_DATA_REQUEST}}
    function success(data) {return {type: AUTHENTICATION_EDIT_PERSONAL_DATA_SUCCESS, data}}
    function failure(error) {return {type: AUTHENTICATION_EDIT_PERSONAL_DATA_FAILURE, error}}
}

// CHANGE EMAIL
function changeAccountEmail(formData) {
    return async dispatch => {
        dispatch(request());

        const result = await userService.changeEmail(formData);
        if (!result.error) {
            dispatch(success(formData));
            return true;
        } else {
            const error = result.message;
            dispatch(failure(error));
        }
    };

    function request() {return {type: AUTHENTICATION_CHANGE_EMAIL_REQUEST }}
    function success(data) {return {type: AUTHENTICATION_CHANGE_EMAIL_SUCCESS, data}}
    function failure(error) {return {type: AUTHENTICATION_CHANGE_EMAIL_FAILURE, error}}
}

// CHANGE PASSWORD
function changeAccountPassword(formData) {
    return async dispatch => {
        dispatch(request());

        const result = await userService.changePassword(formData);
        if (!result.error) {
            dispatch(success(result.data));
            return true;
        } else {
            const error = result.message;
            dispatch(failure(error));
        }
    };

    function request() {return {type: AUTHENTICATION_CHANGE_PASSWORD_REQUEST}}
    function success(data) {return {type: AUTHENTICATION_CHANGE_PASSWORD_SUCCESS, data}}
    function failure(error) {return {type: AUTHENTICATION_CHANGE_PASSWORD_FAILURE, error}}
}