const isServiceAvailable = (service) => {
    switch (service) {
        case 'wishlist':
            return !!(process.env.REACT_APP_WISHLIST);
        case 'archive':
            return !!(process.env.REACT_APP_ARCHIVE);
        case 'preSeason':
            return !!(process.env.REACT_APP_PRE_SEASON);
        case 'landing':
            return !!(process.env.REACT_APP_LANDING);
        default:
            return true;
    }
}

const getDefaultSeason = (seasons, account) => {
    const existPreSeason = seasons && seasons.pre;
    const hasAccountVisibilityInPreSeason = account.preSeason;
    const defaultSeasonSelected = existPreSeason && hasAccountVisibilityInPreSeason && isServiceAvailable('preSeason') ? 'pre' : 'current';
    return defaultSeasonSelected;
}

export const sectionHelper = {
    isServiceAvailable,
    getDefaultSeason
}
