import { combineReducers } from 'redux';

import { error } from './error/error.reducer';
import { authentication } from './authentication/authentication.reducer';
import { user } from './user/user.reducer';
import { registration } from './registration/registration.reducer';
import { confirm } from './confirm/confirm.reducer';
import { brand } from './brand/brand.reducer';
import { catalog } from './catalog/catalog.reducer';
import { selection } from './selection/selection.reducer';
import {wishlist} from './wishlist/wishlist.reducer';
import { order } from './order/order.reducer';
import { modal } from './modal/modal.reducer';
import { pressArea } from './pressArea/press-area.reducer';
import { contact } from './contact/contact.reducer';
import { resetPassword } from './resetPassword/reset-password.reducer';
import toasts from './toasts/toasts.reducer';
import { translation } from './translations/translations.reducer';
import { icon } from './icons/icons.reducer';
import { app } from './app/app.reducer';
import {design} from './design/design.reducer';
import {seasons} from "./season/season.reducer";

const appReducer = combineReducers({
    error,
    authentication,
    user,
    registration,
    confirm,
    brand,
    catalog,
    design,
    wishlist,
    selection,
    order,
    modal,
    pressArea,
    contact,
    toasts,
    resetPassword,
    translation,
    icon,
    app,
    seasons
});

const reducer = (state, action) => (appReducer(state, action));

export default reducer;
