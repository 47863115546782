import React, {useEffect, useRef, useState} from 'react';
import './style.scss';
import * as PropTypes from 'prop-types';

const IconMenu = (props) => {
    const node = useRef();
    const [open, setOpen] = useState(false);
    const {lastItem, children, totalItems} = props;

    const handleClick = (e) => {
        if (node.current.contains(e.target)) {
            // inside click
            return;
        }
        // outside click
        setOpen(false);
    };

    useEffect(() => {
        document.addEventListener('mousedown', handleClick);
        return () => {
            document.removeEventListener('mousedown', handleClick);
        };
    }, []);

    const getBadgeSize = (items) => {
        return items < 10 ? 'badge--small' : items < 99 ? 'badge--normal' : 'badge--big';
    }

    return (
        <div className="icon-menu-component">
            <div className={`icon-menu ${open ? 'icon-menu--active' : ''} ${lastItem ? 'icon-menu--last' : ''}`} ref={node}>
                <button className={`icon-menu__icon ${open ? 'icon-menu__icon--active' : ''}`} onClick={(e) => {e.preventDefault(); setOpen(!open);}}>
                    {children.icon}
                    {totalItems > 0 && !open &&
                        <span className={`badge ${getBadgeSize(totalItems)}`}>
                            <i className="badge__digits">{totalItems}</i>
                        </span>
                    }
                </button>

                {open &&
                    <div className="icon-menu__body">
                        {React.cloneElement(children.body, { callback:  () => setOpen(false) })}
                    </div>
                }
            </div>
        </div>
    )
};

IconMenu.defaultProps = {
    totalItems: 0,
    lastItem: false
}

IconMenu.propTypes = {
    children: PropTypes.object,
    totalItems: PropTypes.number
}

export default IconMenu;