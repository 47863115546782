import React from "react";
import styled from 'styled-components';

const StyledLoading = styled.div`
  background-color: rgba(0, 0, 0, 0.5);
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 2300;
  .loading-container {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 50%;
  }

  .ring {
    display: inline-block;
    position: relative;
    width: 40px;
    height: 40px;
    margin: 0 auto;
  }
  .ring div {
    box-sizing: border-box;
    display: block;
    position: absolute;
    width: 34px;
    height: 34px;
    margin: 2px;
    border: 3px solid #000000;
    border-radius: 50%;
    animation: ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    border-color: #000000 transparent transparent transparent;
  }
  .ring div:nth-child(1) {
    animation-delay: -0.45s;
  }
  .ring div:nth-child(2) {
    animation-delay: -0.3s;
  }
  .ring div:nth-child(3) {
    animation-delay: -0.15s;
  }
  @keyframes ring {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;

export default function Loading() {
    
    return (
        <StyledLoading>
            <div className="loading-container">
                <div className="ring">
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                </div>
            </div>
        </StyledLoading>
    );
}
