import {
    CONTACT_REQUEST,
    CONTACT_SUCCESS,
    CONTACT_FAILURE
} from './contact.action';

const initialState = {
    isFetching: false
};

export function contact(state = initialState, action) {
    switch (action.type) {
        case CONTACT_REQUEST:
            return { ...state, isFetching: true };
        case CONTACT_SUCCESS:
            return { ...state, isFetching: false };
        case CONTACT_FAILURE:
            return { ...state, isFetching: false };
        default:
            return state
    }
}
